import * as yup from "yup";
import { CONSTANTS } from "../../../Helpers/Constants";

/* eslint-disable no-useless-escape */
const re =
  /^(((ftp|http|https):\/\/)?(www\.)?(?!.*(ftp|http|https|www\.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+(\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?)?)|((ftp|http[s]?)\:\/\/(?:www\.)?(?!.*(ftp|http|https|www\.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+([\/?].*)?)$/gm;

  /* eslint-disable no-useless-escape */
const reGREX =
  /^(((ftp|http|https):\/\/)?(www\.)?(?!.*(ftp|http|https|www\.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+(\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?)?)|((ftp|http[s]?)\:\/\/(?:www\.)?(?!.*(ftp|http|https|www\.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+([\/?].*)?)(?![\n\r])/gm;

// const reGREX =
//   /^(((ftp|http|https):\/\/)?(www\.)?(?!.*(ftp|http|https|www))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+(\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?)?)|((ftp|https?):\/\/(?:www\.)?(?!.*(ftp|http|https|www))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+([\/?].*)?)(?![\n\r])/gm;
// // eslint-disable-next-line react-hooks/exhaustive-deps
// const re =
//   /^(((ftp|http|https):\/\/)?(www\.)?(?!.*(ftp|http|https|www))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+(\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?)?)|((ftp|https?):\/\/(?:www\.)?(?!.*(ftp|http|https|www))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+([\/?].*)?)$/gm;

export const OrderFormSchema = yup.object().shape({
  SelectOption: yup
    .object()
    .shape({
      value: yup.string().required("Select an option"),
      label: yup.string().required("Select an option label"),
    })
    .required("Select an option"),

  PageBlogName: yup.string().required("Blog Name is required"),
  BusinessName: yup.string().required("Business Name is required"),
  WebsiteURL: yup
    .string()
    .required("Link is required")
    .matches(re, "URL is not valid"),

  ExistingUrl: yup
    .string()
    .optional()
    .matches(re, "URL is not valid")

    .transform((value, originalValue) => {
      if (!value) {
        return null;
      }
      return originalValue;
    })
    .nullable(),

  ContentRequirements: yup
    .string()
    .required("Content Requirements is required"),
  // .matches(re, "URL is not valid"),
  ContentExamples: yup.array().of(
    yup.object({
      url: yup
        .string()
        .required("Link is required")
        .matches(reGREX, "URL is not valid"),
    })
  ),
  // .string()
  // .required("Link is required")
  // .matches(re, "URL is not valid"),
  Keywords: yup.string().required("keywords is required"),
  [CONSTANTS?.ADDITIONAL_NOTES]: yup.string(),
  // .required("Description is required")
  // .max(10000, CONSTANTS?.DESCRIPTION_MAX),
});
export const defaultValues = {
  SelectOption: "",
  PageBlogName: "",
  BusinessName: "",
  WebsiteURl: "",
  ExistingUrl: null,
  ContentRequirements: "",
  ContentExamples: [{ url: "" }],
  Keywords: "",
  [CONSTANTS?.ADDITIONAL_NOTES]: "",
};
