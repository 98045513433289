import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumbs from "../../Atoms/BreadCrumbs/BreadCrumbs";
import { STYLES } from "../../../Helpers/Constants";
import SideMenu from "../SideBar/SideMenu";
import "./Layout.scss";
import Container from "../../Atoms/Container/Container";
import {
  breadcrumbsAdmin,
  breadcrumbsUser,
} from "../../../Helpers/SideBarItems";
import HamburgerMenu from "../../Atoms/HamburgerMenu/HamburgerMenu";

const Layout = ({ children, isAdmin, client }) => {
  const [selectedLabel, setSelectedLabel] = useState(null);
  const location = useLocation();
  const [isMobile, setIsMobile] = useState(false);
  const breadcrumbs = isAdmin ? breadcrumbsAdmin : breadcrumbsUser;
  const sideMenuClass = isMobile ? STYLES?.SIDEBAR_MEDIUM : STYLES?.SIDEBAR;
  useEffect(() => {
    const { pathname } = location;
    setSelectedLabel(pathname);
  }, [location]);
  const toggleMobileMenu = () => {
    setIsMobile(!isMobile);
  };
  const handleMenuItemSelect = (label) => {
    setSelectedLabel(label);
    setIsMobile(false);
  };
  return (
    <Container className={STYLES?.LAYOUT_CONTENT}>
      <Container className={"hamburger-container"}>
      </Container>
      <Container className={sideMenuClass}>
        <SideMenu
          isAdmin={isAdmin}
          // onSelect={(label) => setSelectedLabel(label)}
          onSelect={handleMenuItemSelect}
          setIsMobile={setIsMobile}
        />
      </Container>
      <Container className={STYLES?.DISPLAY_SCREEN}>
        <Breadcrumbs
          breadcrumbs={breadcrumbs}
          selectedLabel={selectedLabel}
          client={client}
          toggleMobileMenu={toggleMobileMenu}
        />
        <Container className={STYLES?.CONTENT_AREA}>{children}</Container>
      </Container>
    </Container>
  );
};
export default Layout;
