import React from "react";
import Container from "../../Atoms/Container/Container";
import { CONSTANTS, STYLES } from "../../../Helpers/Constants";
import "./LandingPageBottom.scss";
import { Text } from "../../Atoms/Typography/Text";
import RowSpace from "../../../Components/Atoms/RowSpace/RowSpace";

const LandingPageBottom = () => {
  return (
    <Container className={STYLES?.BOTTOM_CONTAINER}>
      <Container className={STYLES?.BOTTOM_BG_CONTAINER}>
        <Container className={STYLES?.BOTTOM_CONTENT_CONTAINER}>
          <Text className={STYLES?.GREEN_STYLED_MED_TEXT}>
            {CONSTANTS?.WORDWELL_SLOGAN}
          </Text>
          <Text className={STYLES?.SMALL_WHITE_TEXT}>
            {CONSTANTS?.WORDWELL_MESSAGE}
          </Text>
          <RowSpace margin={12} />

          <Text className={STYLES?.SMALL_WHITE_TEXT}>
            {CONSTANTS?.MISSION_STATEMENT}
          </Text>

          <RowSpace margin={12} />
          <Container>
            <button className={STYLES?.GET_STARTED_BUTTON}>
              {CONSTANTS?.GET_STARTED}
            </button>
          </Container>
        </Container>
      </Container>
    </Container>
  );
};

export default LandingPageBottom;
