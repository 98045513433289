import React from "react";
import Container from "../../Atoms/Container/Container";
import { CONSTANTS, STYLES } from "../../../Helpers/Constants";
import "./ContentSample.scss";
import { Text } from "../../Atoms/Typography/Text";
import ContentBar from "../ContentBar/ContentBar";
import bg from "../../../Assets/LandingPageAssets/Content_Example_bg.png";
import RowSpace from "../../Atoms/RowSpace/RowSpace";

const ContentSample = () => {
  return (
    <Container className={STYLES?.CONTENT_SAMPLE_CONTAINER}>
      
      <Container className={STYLES?.SMALL_GREEN_ROBOTO}>
        <Text>{CONSTANTS?.OUR_SERVICES}</Text>
      </Container>
      <Container className={STYLES?.LARGE_BLACK_PLAYFAIR}>
        <Text>{CONSTANTS?.UNLEASH_AUTH}</Text>
      </Container>
      <RowSpace margin={42} />

      <Container className={STYLES?.CONTENT_SAMPLE_INNER_CONTAINER}>
        <Container>
          <ContentBar />
        </Container>

        <Container>
          <img
            src={bg}
            alt="logo"
            className={STYLES?.CONTENT_SAMPLE_BG_CONTAINER}
          />
        </Container>
      </Container>
      <RowSpace margin={26} />
      <Container>
        <button className={STYLES?.GET_STARTED_BUTTON}>
          {CONSTANTS?.GET_STARTED}
        </button>
      </Container>
    </Container>
  );
};

export default ContentSample;
