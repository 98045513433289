import React, { useContext } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { CONSTANTS, STYLES } from "../../../Helpers/Constants";
import CrossIcon from "../../../Assets/WhiteCross.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./schema";
import Container from "../../Atoms/Container/Container";
import Image from "../../Atoms/Image";
import { Text } from "../../Atoms/Typography/Text";
import InputField from "../../Atoms/InputField/InputField";
import Button from "../../Atoms/Button";
import { updateProfile } from "../../../Helpers/Functions";
import { errorToast, successToast } from "../../../Helpers/useToast";
import "./NameChangePopUp.scss";
import AppContext from "../../../Context/AppContext";
import CustomLoader from "../../Atoms/CustomLoader/CustomLoader";

const NameChangePopUp = ({ hidePopUpForm }) => {
  const [ErrorMessage, setErrorMessage] = useState(false);
  const { userProfile, setUserProfile, isLoading, setIsLoading } =
    useContext(AppContext);
  const [name, setName] = useState("");
  console.log(name);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: { Name: userProfile?.name || "" },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const { Name: name } = data;
    setIsLoading(true);

    const body = { name: name };
    const resp = await updateProfile(body);
    if (resp.status === 200) {
      successToast("Profile updated successfully");
      const updatedProfile = {
        ...userProfile,
        name: name,
      };
      setUserProfile(updatedProfile);
    } else {
      errorToast("Something went wrong");
    }

    setIsLoading(false);
    hidePopUpForm();
  };

  const formClass = ErrorMessage
    ? STYLES?.USER_MANAGEMENT_LOGIN_FORM_ERROR
    : STYLES?.User_Login_Form;
  const inputFieldClass = ErrorMessage
    ? STYLES?.INPUT_FORM_FIELD_ERROR_USER_MANAGEMENT
    : STYLES?.USER_MANAGEMENT_INPUT_FIELD;

  return (
    <Container className={STYLES?.CHANGE_NAME_PAGE}>
      <Container className={STYLES?.USER_MANAGEMENT_POPUP}>
        <Container onClick={hidePopUpForm} className={STYLES?.CROSS_ICON}>
          <Image src={CrossIcon} />
        </Container>
        <Container className="name-change-popup">
          {isLoading ? (
            <CustomLoader />
          ) : (
            <>
              <Container className="name-change-title">
                <Text
                  variant="h5"
                  className={STYLES?.USER_MANAGEMENT_LOGIN_TEXT}
                >
                  {CONSTANTS?.CHANGE_NAME}
                </Text>
              </Container>
              <form className={formClass} onSubmit={handleSubmit(onSubmit)}>
                <Container className="name-change-fields">
                  <InputField
                    name={CONSTANTS?.NAME}
                    type="text"
                    label={CONSTANTS?.NAME}
                    register={register}
                    onChange={(e) => setName(e.target.value)}
                    field={CONSTANTS?.NAME}
                    errors={errors}
                    maxLength={50}
                    setErrorMessage={setErrorMessage}
                    className={inputFieldClass}
                  />
                </Container>
                <Container className="name-change-buttons">
                  <Button
                    type="button"
                    className={STYLES?.CANCEL_BUTTON}
                    buttonText={CONSTANTS?.CANCEL}
                    onClick={hidePopUpForm}
                  />
                  <Button
                    type={CONSTANTS?.BUTTON_TYPE}
                    className={STYLES?.SEND_BUTTON}
                    buttonText={"Save"}
                  />
                </Container>
              </form>

              {ErrorMessage && (
                <p className={STYLES?.Login_Error_Message}>{ErrorMessage}</p>
              )}
            </>
          )}
        </Container>
      </Container>
    </Container>
  );
};
export default NameChangePopUp;
