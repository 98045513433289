import React from "react";
import Container from "../../Atoms/Container/Container";
import "./SocialLinks.scss";
import { STYLES } from "../../../Helpers/Constants";
import fbIcon from "../../../Assets/LandingPageAssets/fb_icon.svg";
import xIcon from "../../../Assets/LandingPageAssets/twitter_icon.svg";
import linkIcon from "../../../Assets/LandingPageAssets/link_icon.svg";
import ytIcon from "../.././../Assets/LandingPageAssets/YT_icon.svg";

const SocialLinks = () => {
  return (
    <Container className={STYLES?.SOCIAL_LINKS_CONTAINER}>
      <img src={fbIcon} alt="logo" />
      <img src={xIcon} alt="logo" />
      <img src={linkIcon} alt="logo" />
      <img src={ytIcon} alt="logo" />
    </Container>
  );
};

export default SocialLinks;
