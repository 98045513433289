import { CONSTANTS, PaymentMethods, STYLES } from "../../../Helpers/Constants";
import HyperLink from "../../Atoms/HyperLink/HyperLink";
import StatusTag from "../../Atoms/StatusTag/StatusTag";

export const UserBillingColumn = () => {
  return [
    {
      name: CONSTANTS?.SERIAL_NO,
      selector: (row, index) => (
        <div style={{ marginLeft: "20px" }}>{index + 1}</div>
      ),
      width: "150px",
    },

    {
      name: CONSTANTS?.BILLING_DATE,
      selector: (row) =>
        row?.payment_method === "stripe"
          ? (() => {
            const date = new Date(row?.created_at);
            const options = {
              month: "2-digit",
              day: "2-digit",
              year: "numeric",
            };
            const formattedDate = date.toLocaleDateString("en-US", options);
            const formattedDateWithDashes = formattedDate.replace(/\//g, "-");
            return formattedDateWithDashes;
          })()
          : (() => {
              const date = new Date(row?.qb_due_date);
              const options = {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
              };
              const formattedDate = date.toLocaleDateString("en-US", options);
              const formattedDateWithDashes = formattedDate.replace(/\//g, "-");
              return formattedDateWithDashes;
            })(),
      width: "150px",
    },
    {
      name: CONSTANTS?.ORDER_COLUMN,
      selector: (row) => `${row?.order?.business_name}`,
      width: "150px",
    },
    {
      name: CONSTANTS?.PAYMENT_METHOD,
      selector: (row) => PaymentMethods[row?.payment_method],
      width: "150px",
    },
    {
      name: CONSTANTS?.AMOUNT,
      selector: (row) => `${"$"}${row?.amount}${".00"}`,
      width: "150px",
    },
    {
      name: CONSTANTS?.STATUS,
      selector: (row) => <StatusTag data={row} status={row?.status} isAdmin />,
      width: "150px",
    },
    {
      name: CONSTANTS?.LINK_TO_PAY,
      selector: (row) => {
        const isAbsoluteUrl =
          row?.qb_invoice && /^(?:\w+:)\/\//.test(row?.qb_invoice);
        const hrefLink = isAbsoluteUrl
          ? row?.qb_invoice
          : `http://${row?.qb_invoice}`;
        return row?.status === "paid" ? (
          "--"
        ) : (
          <HyperLink
            href={hrefLink}
            text={CONSTANTS?.PAY_NOW}
            className={STYLES?.PAY_NOW_BUTTON}
          />
        );
      },
      width: "150px",
    },
    {
      name: CONSTANTS?.ACTION,
      selector: (row) => "--",
      // <Button
      //   buttonText={CONSTANTS?.DOWNLOAD}
      //   className={STYLES?.BILLING_DOWNLOAD_BUTTON}
      // />
      width: "150px",
      center: true,
    },
  ];
};
export const customStyles = {
  headCells: {
    style: {
      color: "#212936",
      fontFamily: "Inter",
      fontSize: "14px",
      fontWeight: 500,
      lineHeight: "25px",
    },
  },
  headRow: {
    style: {
      background: "#F9FAFB",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
  },
};
