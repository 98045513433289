import BillingIcon from "../Assets/UserSettingSidebarIcons/BillingIcon.svg";
import InactiveBillingIcon from "../Assets/UserSettingSidebarIcons/BillingIcon_inactive.svg";
import PasswordIcon from "../Assets/UserSettingSidebarIcons/PasswordIcon.svg";
import InactivePasswordIcon from "../Assets/UserSettingSidebarIcons/PasswordIcon_inactive.svg";
import ProfileIcon from "../Assets/UserSettingSidebarIcons/ProfileIcon.svg";
import InactiveProfileIcon from "../Assets/UserSettingSidebarIcons/ProfileIcon_inactive.svg";

export const UserSettingSideBar = [
  {
    icon: {
      activeIcon: BillingIcon,
      inactiveIcon: InactiveBillingIcon,
    },
    label: "account",
  },
  {
    icon: { activeIcon: PasswordIcon, inactiveIcon: InactivePasswordIcon },
    label: "password",
  },
  {
    icon: { activeIcon: ProfileIcon, inactiveIcon: InactiveProfileIcon },
    label: "billing",
  },
 
];
