import React, { useEffect, useState } from "react";
import "./UserSetting.scss";
import SettingAccount from "../../Molecules/SettingAccount/SettingAccount";
import SettingPassword from "../../Molecules/SettingPasword/SettingPassword";
import NameChangePopUp from "../../Molecules/NameChangePopUp/NameChangePopUp";
import { UserSettingSideBar } from "../../../Helpers/SettingSidebarItems";
import BillingSetting from "../../Molecules/BillingSetting/BillingSetting";
import { allPaymentMethods } from "../../../Helpers/Functions";

const body = {
  account: (props) => <SettingAccount {...props} />,
  password: (props) => <SettingPassword {...props} />,
  billing: (props) => <BillingSetting {...props} />,
};

function UserSetting() {
  const [activeMenu, setActiveMenu] = useState("account");
  const [showPopUp, setShowPopUp] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState({});
  const showChangePopUp = () => {
    setShowPopUp(true);
  };
  const hidePopUpForm = () => {
    setShowPopUp(false);
  };
  const handleOptionChange = (option) => {
    setActiveMenu(option);
  };
  useEffect(() => {
    allPaymentMethods().then((response) => {
      // console.log("All Payments", response);
      setPaymentMethods(response);
    });
  }, []);

  return (
    <>
      {!showPopUp ? (
        <div className="setting-layout">
          <div className="setting-left-bar">
            <div className="setting-left-bar-child">
              {UserSettingSideBar?.map((item) => (
                <p
                  key={item.label}
                  className={`${
                    item.label === activeMenu ? "active" : "option"
                  } setting-left-bar-items`}
                  onClick={() => handleOptionChange(item.label)}
                >
                  <img
                    src={
                      item.label === activeMenu
                        ? item.icon.activeIcon
                        : item.icon.inactiveIcon
                    }
                    className="setting-left-icon"
                    alt={item.label}
                  />
                  {item.label === "account" ? "Your Profile" : item.label}
                </p>
              ))}
            </div>
          </div>

          <div className="setting-main-content">
            <div className="setting-main-content-body">
              {body[activeMenu]({ onClicked: showChangePopUp, paymentMethods })}
            </div>
          </div>
        </div>
      ) : (
        // <div className="setting-layout">
        //   <div className="setting-left-bar">
        //     <div className="setting-left-bar-child">
        //       {menu.map((item) => (
        //         <p
        //         key={item}
        //         className={`${
        //           item === activeMenu ? "active" : "option"
        //         } setting-left-bar-items`}
        //         onClick={() => handleOptionChange(item)}
        //         >
        //           {console.log("New Item",item)}
        //           {item === "account" ? "Your Profile" : item}
        //         </p>
        //       ))}
        //     </div>
        //   </div>

        //   <div className="setting-main-content">
        //     <div className="setting-main-content-body">
        //       {body[activeMenu]({ onClicked: showChangePopUp })}
        //     </div>
        //   </div>
        // </div>
        <NameChangePopUp hidePopUpForm={hidePopUpForm} />
      )}
    </>
  );
}

export default UserSetting;
