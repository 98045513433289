import DotsMenu from "../../Atoms/DotsMenu/DotsMenu";
import StatusTag from "../../Atoms/StatusTag/StatusTag";
import Button from "../../Atoms/Button";
import { ROUTES, STYLES } from "../../../Helpers/Constants";
import Image from "../../Atoms/Image";
import Container from "../../Atoms/Container/Container";
import PaperClip from "../../../Assets/PaperClip.svg";
import { ViewButton } from "./ChildComponents";

export const columns = (navigate) => {
  const onView = (row) => {
    const currentPath = window.location.pathname;
    const newPath = `${currentPath}${ROUTES.ORDER_DETAILS}/${row.id}`;
    navigate(newPath, {
      state: row,
    });
  };

  const options = (row) => {
    return [<ViewButton onView={() => onView(row)} />];
  };

  return [
    {
      name: "Client name",
      cell: (row) => <div style={{ marginLeft: "20px" }}>{row?.user.name}</div>,
      maxWidth: "10%",
    },
    {
      name: "Name",
      selector: (row) => row?.business_name,
      maxWidth: "15%",
    },
    {
      name: "Order type",
      selector: (row) => row?.page_type,
      maxWidth: "20%",
    },

    {
      name: "Date ",
      selector: (row) => {
        const date = new Date(row?.created_at);
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const year = date.getFullYear();
        return `${month}-${day}-${year}`;
      },
      maxWidth: "10%",
    },
    {
      name: "Status",
      id: "status",
      cell: (row) => (
        <StatusTag data={row} status={row?.order_status} isAdmin />
      ),
      maxWidth: "20%",
    },
    {
      name: "Download",
      selector: (row) => (
        <>
          <div>
            {row?.revisions?.length > 0 ? (
              <Container className={STYLES?.UPLOADED_FILE_NAME_CONTAINER}>
                <Image src={PaperClip} alt="uploaded" />
                <a
                  target="_Blank"
                  href={row?.revisions[0]?.document_url}
                  className={STYLES?.UPLOAD_TEXT}
                  rel="noopener noreferrer"
                >
                  {row?.revisions[0]?.document_url}
                </a>
              </Container>
            ) : (
              <Button
                className="upload-file-button"
                buttonText="Upload a file"
                onClick={() => {
                  onView(row);
                }}
              />
            )}
          </div>
        </>
      ),
      maxWidth: "300px",
    },
    {
      name: "Action",
      cell: (row) => <DotsMenu options={options(row)} />,
      maxWidth: "100px",
      center: true,
    },
  ];
};
export const customStyles = {
  headCells: {
    style: {
      color: "#212936",
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "25px",
    },
  },
  headRow: {
    style: {
      // paddingLeft: "20px",
      background: "#F9FAFB",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
  },
};
