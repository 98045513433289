import * as yup from "yup";
import { CONSTANTS } from "../../../Helpers/Constants";
export const schema = yup.object().shape({
  Name: yup
    .string()
    .required(CONSTANTS?.NAME_MISSING)
    .max(50, CONSTANTS?.NAME_MAX)
    .min(4, "Name must be atleast 4 characters long"),
});

export const defaultValues = {
  Name: "",
};
