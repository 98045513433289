import React from "react";
import Container from "../Container/Container";
import "./SampleIcon.scss";
const SampleIcon = ({ src }) => {
  return (
    <Container>
      <img src={src} alt="Header Logo" className="sample-logo" />
    </Container>
  );
};

export default SampleIcon;
