import { Link, useNavigate } from "react-router-dom";
import React, { useContext, useState, useEffect } from "react";
import "./SignUpForm.scss";
import Container from "../../Atoms/Container/Container";
import { useForm } from "react-hook-form";
import { Text } from "../../Atoms/Typography/Text";
import { yupResolver } from "@hookform/resolvers/yup";
import { CONSTANTS, ROUTES, STYLES } from "../../../Helpers/Constants";
import Image from "../../Atoms/Image";
import logo from "../../../Assets/logo.svg";
import InputField from "../../Atoms/InputField/InputField";
import {
  clientSignIn,
  clientSignUp,
  fetchUserProfile,
  updateProfile,
  // updateProfile,
} from "../../../Helpers/Functions";
import InputFieldPassword from "../../Atoms/InputFieldPassword";
import { defaultValues, schema } from "./schema";
import PrimaryButton from "../../Atoms/ButtonPrimary/ButtonPrimary";
import AppContext from "../../../Context/AppContext";
import { errorToast } from "../../../Helpers/useToast";
import CustomLoader from "../../Atoms/CustomLoader/CustomLoader";

const SignUpForm = () => {
  const { setIsAuthenticated, setUserType, setUserProfile } =
    useContext(AppContext);
  const [ErrorMessage, setErrorMessage] = useState(false);
  const { isLoading, setIsLoading } = useContext(AppContext);

  const navigate = useNavigate();
  let isError = false;
  const formClass =
    isError || ErrorMessage ? STYLES?.LOGIN_FORM_ERROR : STYLES?.LOGIN_FORM;

  const InputFieldClass = isError
    ? STYLES?.INPUT_FIELD_ERROR
    : STYLES?.LOGIN_INPUT_FIELD;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const storeAccessToken = (idToken, refreshToken) => {
    localStorage.setItem("accessToken", idToken);
    localStorage.setItem("refresh_token", refreshToken);
  };
  const onSubmit = async (data) => {
    const { Name: name, Email: email, Password: password } = data;
    if (!email || !password) {
      setErrorMessage("enter correct credentials");
      return;
    }
    if (!isValidEmail(email)) {
      setErrorMessage("Please enter a valid email address");
      return;
    }

    try {
      const responseData = await clientSignUp(name, email, password);
      console.log(responseData);

      try {
        setIsLoading(true);
        const responseData = await clientSignIn(email, password, true);
        localStorage.setItem("payment_method", "stripe");
        localStorage.setItem("userType", "user");
        sessionStorage.setItem("userType", "user");
        storeAccessToken(responseData?.idToken, responseData?.refreshToken);
        const body = { first_login: false };
        const updRes = await updateProfile(body);
        console.log("Updat Res", updRes);

        fetchUserProfile()
          .then((res) => {
            setUserProfile(res);
            setIsAuthenticated(true);
            setUserType("user");
            navigate(ROUTES?.ORDER);
          })
          .catch((err) => {
            console.log("err: ", err);
          });
      } catch (error) {
        setIsLoading(false);
        if (error.message.includes("Authentication failed")) {
          setErrorMessage(CONSTANTS?.ERROR_MESSAGE_API_FAIL);
          setIsAuthenticated(false);
          setUserType(null);
        }
      }
    } catch (error) {
      setIsLoading(false);
      if (error.message.includes("Authentication failed")) {
        setErrorMessage(CONSTANTS?.ERROR_MESSAGE_API_FAIL);
        setIsAuthenticated(false);
        setUserType(null);
      }
    }
  };

  useEffect(() => {
    const loggedOut = localStorage.getItem("LogOut");

    if (loggedOut) {
      errorToast(loggedOut);
      setTimeout(() => {
        localStorage.removeItem("LogOut");
      }, 1000);
    }
  }, []);
  return (
    <Container className={STYLES?.SIGN_UP_FORM_PARENT}>
      <Container className={STYLES?.SIGN_UP_PAGE}>
        {!isLoading ? (
          <Container className={STYLES?.LOGIN_FORM_CONTAINER}>
            <form className={formClass} onSubmit={handleSubmit(onSubmit)}>
              <Container className={STYLES?.SIGN_UP_FORM_HEADER}>
                <Container>
                  <Image src={logo} alt="Logo" />
                </Container>
                <Container className={STYLES?.LOGIN_TEXT}>
                  <Text>{CONSTANTS?.SIGN_UP_TEXT} </Text>
                </Container>
              </Container>
              <InputField
                name={CONSTANTS?.NAME}
                type="text"
                label={CONSTANTS?.FULL_NAME}
                register={register}
                field={CONSTANTS?.NAME}
                errors={errors}
                maxLength={50}
                setErrorMessage={setErrorMessage}
                className={InputFieldClass}
              />
              <InputField
                name={CONSTANTS?.EMAIL}
                field={CONSTANTS?.EMAIL}
                type={CONSTANTS?.EMAIL_TYPE}
                placeholder={CONSTANTS?.EMAIL_PLACEHOLDER}
                register={register}
                errors={errors}
                maxLength={50}
                label={CONSTANTS?.EMAIL_ADDRESS}
                onChange={(e) => {
                  setValue(CONSTANTS?.EMAIL, e.target.value);
                }}
                className={InputFieldClass}
              />
              <InputFieldPassword
                name={CONSTANTS?.PASSWORD}
                type={CONSTANTS?.PASSWORD_TYPE}
                field={CONSTANTS?.PASSWORD}
                placeholder=""
                register={register}
                label={CONSTANTS?.CREATE_PASSWORD}
                disabled={false}
                errors={errors}
                maxLength={50}
                onChange={(e) => {
                  setValue(CONSTANTS?.PASSWORD, e.target.value);
                }}
                className={InputFieldClass}
              />
              <InputFieldPassword
                name={CONSTANTS?.CONFIRMPASSWORD}
                type={CONSTANTS?.PASSWORD_TYPE}
                field={CONSTANTS?.CONFIRMPASSWORD}
                placeholder=""
                register={register}
                label={CONSTANTS?.CONFIRM_PASSWORD}
                disabled={false}
                errors={errors}
                maxLength={50}
                onChange={(e) => {
                  setValue(CONSTANTS?.CONFIRMPASSWORD, e.target.value);
                }}
                className={InputFieldClass}
              />
              <PrimaryButton
                type={CONSTANTS?.BUTTON_TYPE}
                buttonText={CONSTANTS?.SIGN_UP}
              />
              <Container className={STYLES?.SIGN_UP_BOTTOM}>
                <Text className={STYLES?.LIGHT_GREY_TEXT}>
                  {CONSTANTS?.ALREADY_HAVE_ACCOUNT}
                  <Link
                    to={ROUTES?.USER_LOGIN}
                    className={STYLES?.UPLOAD_TEXT}
                    target="_blank"
                  >
                    {CONSTANTS?.LOGIN}
                  </Link>
                </Text>
              </Container>

              {ErrorMessage && (
                <p className={STYLES?.Login_Error_Message}>{ErrorMessage}</p>
              )}
            </form>
          </Container>
        ) : (
          <CustomLoader />
        )}

        <Container className={STYLES?.All_Rights_Reserved}>
          <Text>{CONSTANTS?.ALL_RIGHTS_TEXT}</Text>
        </Container>
      </Container>
    </Container>
  );
};
export default SignUpForm;
