import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Text } from "../../Atoms/Typography/Text";
import LogoImg from "../../../Assets/logo.svg";
import { AdminSideBar, UserSideBar } from "../../../Helpers/SideBarItems";
import "./SideMenu.scss";
import Image from "../../Atoms/Image/Image";
import { STYLES } from "../../../Helpers/Constants";
const SideMenu = ({ onSelect, isAdmin, setIsMobile }) => {
  const [activeLink, setActiveLink] = useState(null);
  const location = useLocation();
  useEffect(() => {
    const { pathname } = location;
    const trimmedPathname = pathname.split("/").slice(0, 3).join("/");
    setActiveLink(trimmedPathname);
  }, [location]);
  const menuItems = isAdmin ? AdminSideBar : UserSideBar;
  const MenuItems = ({ isActive, icon, label, onClick }) => (
    <li
      className={`${STYLES.SIDEBAR_ITEM} ${isActive ? "active" : ""}`}
      onClick={onClick}
    >
      <Image
        className={STYLES?.SIDEBAR_ICON}
        src={isActive ? icon.activeIcon : icon.inactiveIcon}
      />
      <Text
        variant="p"
        className={`${STYLES?.ACTIVE_LINK} ${
          isActive ? STYLES?.ACTIVE_TEXT : ""
        }`}
      >
        {label}
      </Text>
    </li>
  );
  const handleItemClick = (link, label) => {
    setActiveLink(link);
    onSelect(label);
    setIsMobile(false);
  };

  return (
    <>
      <Image className={STYLES?.LOGO_IMG} src={LogoImg} />
      <ul className={STYLES?.NAVBAR}>
        {menuItems?.map(({ path, label, icon }, index) => (
          <Link key={index} to={path}>
            <MenuItems
              isActive={activeLink === path}
              icon={icon}
              label={label}
              // onClick={() => handleItemClick(path, label)}
              onClick={() => handleItemClick(path, label)}
            />
          </Link>
        ))}
      </ul>
    </>
  );
};

export default SideMenu;
