import React from "react";
import Container from "../Container/Container";
import { STYLES } from "../../../Helpers/Constants";
import Image from "../Image";
import "./ButtonCard.scss";
import { Text } from "../Typography/Text";

const ButtonCard = ({ CardText, image, className, link }) => {
  const isAbsoluteUrl = link && /^(?:\w+:)\/\//.test(link);
  return (
    <Container className={className}>
      <a
        href={isAbsoluteUrl ? link : `http://${link}`}
        target={"_blank"}
        rel="noopener noreferrer"
      >
        <Container className={STYLES?.BUTTON_CARD_INNER_CONTAINER}>
          <Image src={image} />
          <Text>{CardText}</Text>
        </Container>
      </a>
    </Container>
  );
};

export default ButtonCard;
