import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { UpdatePayments } from "../../../Helpers/Functions";
import PrimaryButton from "../../Atoms/ButtonPrimary/ButtonPrimary";
import { CONSTANTS } from "../../../Helpers/Constants";
import Container from "../../Atoms/Container/Container";
import "./PaymentSetting.scss";
const stripePromise = loadStripe("pk_live_EvYfmrWezzzSj4fLlEZixIoQ");
const PaymentSetting = () => {
  const handleClick = async (event) => {
    const update_payment = await UpdatePayments();
    window.open(update_payment.url, "_self");

    const stripe = await stripePromise;
    const { error } = await stripe.redirectToCheckout({});
    console.log(error);
  };
  return (
    <PrimaryButton
      buttonText={CONSTANTS?.UPDATE_PAYMENT_METHOD}
      onClick={handleClick}
    />
  );
};

export default PaymentSetting;
