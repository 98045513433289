import React, { useState, useEffect } from "react";
import { updateStatus } from "../../../Helpers/Functions";
import DownArrowIcon from "../../../Assets/DownArrow_brown.svg";
import { OrderStatuses } from "../../../Helpers/Constants";

const StatusTag = ({ data, status, isAdmin = false }) => {
  const [selectedOption, setSelectedOption] = useState("neworder");
  const [isOpen, setIsOpen] = useState(false);

  let backgroundColor, color;

  const handleDropdownChange = (value) => {
    setSelectedOption(value);
    updateStatus(data?.id, value);
    setIsOpen(false);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  switch (status) {
    case "unpaid":
    case "invited":
    case "neworder":
    case "inprogress":
      backgroundColor = "#FEF3C7";
      color = "#92400E";
      // statusBorder = "2px solid  #92400E";
      break;
    case "paid":
    case "accepted":
    case "signedup":
    case "completed":
      backgroundColor = "#D1FAE5";
      color = "#065F46";
      // statusBorder = "2px solid green";
      break;
    case "revise":
      backgroundColor = "#FEE2E2 ";
      color = "#991B1B";
      // statusBorder = "2px solid #991B1B";
      break;
    default:
      backgroundColor = "statusBorder";
      color = "#065F46";
    // statusBorder = "2px solid #065F46";
  }

  const cellStyle = {
    padding: "8px",
    textAlign: "center",
    display: "inline-flex",
    gap: "0.5rem",
    textTransform: "capitalize",
    borderRadius: "4px",
    backgroundColor: backgroundColor,
    color: color,
    position: "relative",
  };

  const dropdownStyle = {
    position: "absolute",
    top: "calc(100% + 5px)",
    left: 0,
    width: "100%",
    backgroundColor: "#ffffff",
    borderRadius: "4px",
    zIndex: 9999,
  };

  useEffect(() => {
    setSelectedOption(status);
  }, [status]);

  return (
    <>
      {selectedOption === "neworder" && status === "neworder" ? (
        <div style={{ position: "relative" }}>
          <div style={cellStyle} onClick={handleToggle}>
            {isAdmin
              ? OrderStatuses[selectedOption]?.admintext
              : OrderStatuses[selectedOption]?.text}
            {isAdmin ? (
              <div style={{ marginLeft: "auto", cursor: "pointer" }}>
                <img src={DownArrowIcon} alt="Down Arrow" />
              </div>
            ) : null}
          </div>
          {isAdmin && isOpen && (
            <div style={dropdownStyle}>
              <div
                onClick={() => handleDropdownChange("neworder")}
                style={{ padding: "8px", cursor: "pointer" }}
              >
                New Order
              </div>
              <div
                onClick={() => handleDropdownChange("inprogress")}
                style={{ padding: "8px", cursor: "pointer" }}
              >
                In Progress
              </div>
            </div>
          )}
        </div>
      ) : (
        <div style={cellStyle}>
          {isAdmin
            ? OrderStatuses[selectedOption]?.admintext
            : OrderStatuses[selectedOption]?.text}
        </div>
      )}
    </>
  );
};

export default StatusTag;
