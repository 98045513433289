import React from "react";
import Container from "../../Atoms/Container/Container";
import "./LandingPage.scss";
import { STYLES } from "../../../Helpers/Constants";
import Header from "../../Molecules/Header/Header";
import Monogram from "../../Atoms/Monogram/Monogram";
import mono from "../../../Assets/LandingPageAssets/badge.svg";
import HeaderStyledText from "../../Molecules/HeaderStyledText/HeaderStyledText";
import LogoBar from "../../Molecules/LogoBar/LogoBar";
import BriefIntro from "../../Molecules/BriefIntro/BriefIntro";
import ContentSample from "../../Molecules/ContentSample/ContentSample";
import LandingPageFooter from "../../Molecules/LandingPageFooter/LandingPageFooter";
import LandingPageBottom from "../../Molecules/LandingPageBottom/LandingPageBottom";
const LandingPage = () => {
  return (
    <Container className={STYLES?.LANDING_PAGE_MAIN_CONTAINER}>
      <Container className={STYLES?.HEADER_BG_CONTAINER}>
        <Container className={STYLES?.LANDING_PAGE_HEADER_BG}>
          <Header />
        </Container>
        <LogoBar />
      </Container>
      <Container className={STYLES?.HEADER_TEXT_CONTAINER}>
        <HeaderStyledText />
        <Monogram imageSrc={mono} />
      </Container>
      <BriefIntro />
      <ContentSample />
      <LandingPageBottom />
      <LandingPageFooter />
    </Container>
  );
};

export default LandingPage;
