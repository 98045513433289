import React, { useState } from "react";
import Container from "../../Atoms/Container/Container";
import { CONSTANTS, ROUTES, STYLES } from "../../../Helpers/Constants";
import "./OrderDetails.scss";
import { Text } from "../../Atoms/Typography/Text";
import { useLocation, useNavigate } from "react-router-dom";
import Accordion from "../../Atoms/Accordion/Accordion";
import AccordionData from "../../Atoms/AccordionData";
import CommentSection from "../../Atoms/CommentSection";
import PrimaryButton from "../../Atoms/ButtonPrimary/ButtonPrimary";
import { approveOrder, reviseOrder } from "../../../Helpers/Functions";
import { errorToast, successToast } from "../../../Helpers/useToast";

const OrderDetails = () => {
  const navigate = useNavigate();
  const [comment, setComment] = useState("");
  const location = useLocation();
  const stateData = location.state;
  const onApprove = () => {
    approveOrder(comment, stateData?.id).then((res) => {
      navigate(ROUTES?.ORDER_MANAGEMENT);
      successToast("Order approved successfully");
    });
  };

  const handleRevision = () => {
    if (comment !== "") {
      reviseOrder(comment, stateData?.id).then((res) => {
        navigate(ROUTES?.ORDER_MANAGEMENT);
        successToast("Order revised successfully");
      });
    } else {
      errorToast("Please add a comment");
    }
  };

  return (
    <Container className={STYLES?.ORDER_DETAILS_CONTAINER}>
      <Container className={STYLES?.ORDER_DETAILS_HEADER}>
        <Text>{CONSTANTS?.ORDER_DETAILS}</Text>
        {stateData?.revisions?.length > 0 ? (
          <>
            <Container>
              <PrimaryButton
                buttonText={CONSTANTS?.DOWNLOAD_FILE}
                download
                downloadLink={
                  stateData?.revisions[stateData?.revisions?.length - 1]
                    ?.document_url
                }
              />
            </Container>
          </>
        ) : null}
      </Container>
      <Accordion
        title={stateData?.page_type}
        innerElement={<AccordionData data={stateData} />}
        status
        statusText={stateData?.order_status}
        />
      <Accordion
        title={CONSTANTS?.ALL_COMMENTS}
        innerElement={
          <CommentSection
          setComment={setComment}
          comments={stateData?.comments}
          textAreaDisabled={
              stateData?.order_status === "completed" ||
              stateData?.order_status === "neworder" ||
              stateData?.order_status === "inprogress"
            }
          />
        }
      />
      {stateData?.order_status !== "completed" ? (
        <Container className={STYLES?.CREATE_ORDER_FORM_BUTTON}>
          <PrimaryButton
            buttonText={CONSTANTS?.APPROVE}
            onClick={onApprove}
            disabled={
              stateData?.order_status === "neworder" ||
              stateData?.order_status === "inprogress"
            }
          />
          <PrimaryButton
            type="button"
            buttonText={CONSTANTS?.ADD_REVISION}
            onClick={handleRevision}
            isCancel
            disabled={
              stateData?.order_status === "neworder" ||
              stateData?.order_status === "inprogress"
            }
          />
        </Container>
      ) : null}
    </Container>
  );
};

export default OrderDetails;
