export function TimeFormat(timeStr) {
  console.log("Given Time", timeStr);
  // Extract hours, minutes, and seconds from the time string
  const [hours, minutes] = timeStr.split(":").map(parseFloat);

  // Format the hours into 12-hour format
  const formattedHours = hours % 12 === 0 ? 12 : hours % 12;

  // Determine if it's AM or PM
  const period = hours < 12 ? "AM" : "PM";

  // Format the time as "hours:minutes AM/PM"
  const formattedTime = `${formattedHours}:${
    minutes < 10 ? "0" : ""
  }${minutes} ${period}`;

  return formattedTime;
}
