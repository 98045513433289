import React, { useEffect, useState } from "react";
import SearchResult from "../../Molecules/SearchResult";
import CustomLoader from "../../Atoms/CustomLoader/CustomLoader";
import BoldBlackHeading from "../../Atoms/BoldBlackHeading/BoldBlackHeading";
import RowSpace from "../../Atoms/RowSpace/RowSpace";
import DataTable from "react-data-table-component";
import DealsTableTitleRow from "../../Atoms/DealsTableTitleRow";
import { CONSTANTS, STYLES } from "../../../Helpers/Constants";
import { columns, customStyles } from "./OrderColumn";
import CreateNewUser from "../../Molecules/CreateNewUser/CreateNewUser";
import CustomPagination from "../../Molecules/CustomPagination/CustomPagination";
import Container from "../../Atoms/Container/Container";
import "./UserOrderManagement.scss";
import { useNavigate } from "react-router-dom";
import { adminOrderData, formatFilter } from "../../../Helpers/Functions";
import { Orderoptions } from "../../../Helpers/FilterOptions";

const limit = 6;

const headingArr = [
  CONSTANTS?.NAME,
  CONSTANTS?.EMAIL_ADRESS,
  CONSTANTS?.CREATED_ON,
  CONSTANTS?.PAYMENT_METHOD,
  CONSTANTS?.STATUS,
  CONSTANTS?.ACTION,
];

const UserOrderManagement = () => {
  // const [showPopup, setShowPopup] = useState(false);
  const [count, setCount] = useState(1);
  const [dealsData, setDealsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedData, setSortedData] = useState([]);
  const [sort, setSort] = useState("");
  const [filter, setFilter] = useState("");
  const [visibility, setVisibility] = useState(false);
  const [sortVisibilty, setsortVisibilty] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const formattedFilter = formatFilter(filter);

    adminOrderData({
      limit,
      currentPage,
      search,
      sort,
      filter: formattedFilter,
      setDealsData,
      setLoading,
      setCount,
    });
  }, [search, filter, sort, currentPage]);

  const handleSearch = (newSearch) => {
    setSearch(newSearch);
  };

  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    if (dealsData && dealsData.length > 0) {
      setSortedData([...dealsData]);
    }
  }, [dealsData]);

  return (
    <Container className={STYLES?.USER_MANAGEMENT_SCREEN}>
      <Container className={STYLES?.MainContent}>
        <BoldBlackHeading text={CONSTANTS?.ORDER_MANAGEMENT} />
        <Container className={STYLES?.SEARCH_CONTENT}>
          <SearchResult
            // setShowPopup={setShowPopup}
            onSearch={handleSearch}
            setSearch={setSearch}
            setSort={setSort}
            setFilter={setFilter}
            filter={filter}
            sortingValue={true}
            count={count}
            visibility={visibility}
            setVisibility={setVisibility}
            options={{ Status: Orderoptions }}
            sortVisibilty={sortVisibilty}
            setsortVisibilty={setsortVisibilty}
            showFilter={true}
          />
        </Container>
        <RowSpace margin={33} />
        {!loading ? (
          <>
            {dealsData?.length > 0 ? (
              <div className={STYLES?.CUSTOM_TABLE}>
                <DataTable
                  customStyles={customStyles}
                  noHeader={true}
                  fixedHeader={true}
                  fixedHeaderScrollHeight={"48dvh"}
                  columns={columns(navigate)}
                  data={sortedData}
                  pagination
                  paginationPerPage={limit}
                  paginationRowsPerPageOptions={[10, 20, 30]}
                  paginationComponent={(props) => (
                    <CustomPagination
                      {...props}
                      currentPage={currentPage}
                      limit={limit}
                      count={count}
                      setCurrentPage={setCurrentPage}
                      onChangePage={handleChangePage}
                    />
                  )}
                />
              </div>
            ) : (
              <>
                <DealsTableTitleRow list={headingArr} />
                {!loading && (
                  <CreateNewUser
                    text={CONSTANTS?.NO_ORDER_RECIEVED_YET}
                    text1={CONSTANTS?.NO_ORDER}
                  />
                )}
              </>
            )}
          </>
        ) : (
          <CustomLoader />
        )}
      </Container>
    </Container>
  );
};
export default UserOrderManagement;
