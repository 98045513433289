import * as yup from "yup";
import { CONSTANTS } from "../../../Helpers/Constants";

export const schema = yup.object().shape({
  Name: yup
    .string()
    .required(CONSTANTS?.NAME_ON_THE_CARD)
    .max(100, CONSTANTS?.EMAIL_MAX)
    .min(4, CONSTANTS?.EMAIL_MIN),
 CardNumber: yup
    .string()
    .required(CONSTANTS?.CARD_NUMBER)
    .min(6, CONSTANTS?.PASSWORD_MIN)
    .max(100, CONSTANTS?.PASSWORD_MAX),
});

export const defaultValues = {
  Name: "",
  CardNumber: "",
};
