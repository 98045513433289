import { CONSTANTS, PaymentMethods } from "../../../Helpers/Constants";
import DotsMenu from "../../Atoms/DotsMenu/DotsMenu";
import StatusTag from "../../Atoms/StatusTag/StatusTag";
import { ViewButton } from "./ChildComponents";

export const BillingColumn = () => {
  const options = (row) => {
    return [<ViewButton data={row} />];
  };

  return [
    {
      name: "Client name",
      cell: (row) => (
        <div style={{ marginLeft: "20px" }}>{row?.user?.name}</div>
      ),
    },

    {
      name: "Billing date ",
      selector: (row) => {
        const date = new Date(row?.created_at);
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const year = date.getFullYear();
        return `${month}-${day}-${year}`;
      },
    },
    {
      name: CONSTANTS?.ORDER_COLUMN,
      selector: (row) => `${row?.order?.business_name}`,
      width: "150px",
    },
    {
      name: "Amount",
      selector: (row) => `${"$"}${row?.amount}${".00"}`,
    },
    {
      name: "Payment method",
      selector: (row) => PaymentMethods[row?.payment_method],
    },
    {
      name: "Status",
      id: "status",
      cell: (row) => <StatusTag data={row} status={row?.status} isAdmin />,
    },

    {
      name: "Action",
      cell: (row) => <DotsMenu options={options(row)} />,
      maxWidth: "160px",
      center: true,
    },
  ];
};
export const customStyles = {
  headCells: {
    style: {
      color: "#212936",
      fontFamily: "Inter",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "32px",
    },
  },
  headRow: {
    style: {
      // paddingLeft: "20px",
      background: "#F9FAFB",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
  },
};
