import React from "react";
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Container from "../../Atoms/Container/Container";
import { CONSTANTS, STYLES } from "../../../Helpers/Constants";
import InputField from "../../Atoms/InputField/InputField";
import { Text } from "../../Atoms/Typography/Text";
import Button from "../../Atoms/Button";
import { yupResolver } from "@hookform/resolvers/yup";
import Image from "../../Atoms/Image";
import CrossIcon from "../../../Assets/WhiteCross.svg";
import CustomSelect from "../../Atoms/CustomSelect/CustomSelect";
import "./UserManagementLogin.scss";
import { defaultValues, schema } from "./schema";
import { userManagementLogin } from "../../../Helpers/Functions";
import { ERROR_MESSAGES } from "../../../Helpers/APIUrls";
import { successToast } from "../../../Helpers/useToast";

const options = [
  { value: "stripe", label: CONSTANTS?.STRIPE },
  { value: "quickbooks", label: CONSTANTS?.QUICK_BOOKS },
];
const UserManagementLogin = ({ hidePopUpForm, newData }) => {
  const [ErrorMessage, setErrorMessage] = useState(false);

  const {
    handleSubmit,
    register,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const customStyles = {
    menu: (provided) => ({
      ...provided,
      padding: 5,
      margin: 0,
    }),
    control: (provided, state) => ({
      ...provided,
      appearance: "none",
      "-moz-appearance": "none",
      "-webkit-appearance": "none",
      border: errors?.["SelectOption"]
        ? "3px solid #C52A1A"
        : state.isFocused
        ? "1px solid #D1D5DB"
        : "1px solid #ced4da",
      ":hover": {
        border: errors?.["SelectOption"]
          ? "3px solid #C52A1A"
          : state.isFocused
          ? "1px solid #D1D5DB"
          : "1px solid #ced4da",
      },
      borderRadius: "5px",
      boxShadow: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#059669" : "white",
      color: state.isSelected ? "white" : "black",
    }),
  };

  const onSubmit = async (data) => {
    // const { Name: name, Email: email, SelectOption: SelectOption } = data;
    const { Name: name, Email: email, SelectOption } = data;

    const payment_method = SelectOption.value;
    if (!email || !name || !SelectOption) {
      setErrorMessage("Please fill complete form");
      return;
    }

    if (!isValidEmail(email)) {
      setErrorMessage("Please enter a valid email address");
      return;
    }

    try {
      const accessToken = localStorage.getItem("accessToken");
      const responseData = await userManagementLogin(
        email,
        name,
        payment_method,
        accessToken
      );
      newData(responseData);
      successToast(CONSTANTS?.USER_ADDED_TEXT);
      hidePopUpForm();
    } catch (error) {
      if (error.message.includes(ERROR_MESSAGES?.CONFLICT)) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage(error?.message?.includes(ERROR_MESSAGES?.AUTH_FAILED));
      }
    }
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const formClass = ErrorMessage
    ? STYLES?.USER_MANAGEMENT_LOGIN_FORM_ERROR
    : STYLES?.User_Login_Form;
  const inputFieldClass = ErrorMessage
    ? STYLES?.INPUT_FORM_FIELD_ERROR_USER_MANAGEMENT
    : STYLES?.USER_MANAGEMENT_INPUT_FIELD;

  return (
    <Container className={STYLES?.USER_MANAGEMENT_LOGIN_PAGE}>
      <Container className={STYLES?.USER_MANAGEMENT_POPUP}>
        <Container onClick={hidePopUpForm} className={STYLES?.CROSS_ICON}>
          <Image src={CrossIcon} />
        </Container>
        <Container className={STYLES?.LOGIN_USER_MANAGEMENT}>
          <Container>
            <Text variant="h5" className={STYLES?.USER_MANAGEMENT_LOGIN_TEXT}>
              {CONSTANTS?.NEW_WORDWELL_USER}
            </Text>
            <Container>
              <form className={formClass} onSubmit={handleSubmit(onSubmit)}>
                <InputField
                  name={CONSTANTS?.NAME}
                  type="text"
                  label={CONSTANTS?.NAME}
                  register={register}
                  field={CONSTANTS?.NAME}
                  errors={errors}
                  maxLength={50}
                  setErrorMessage={setErrorMessage}
                  className={inputFieldClass}
                />
                <InputField
                  label={CONSTANTS?.EMAIL_ADDRESS}
                  name={CONSTANTS?.EMAIL}
                  type={CONSTANTS?.EMAIL_TYPE}
                  field={CONSTANTS?.EMAIL}
                  maxLength={50}
                  onChange={(e) => {
                    setValue(CONSTANTS?.EMAIL, e.target.value);
                  }}
                  register={register}
                  setErrorMessage={setErrorMessage}
                  errors={errors}
                  className={inputFieldClass}
                />

                <Controller
                  name="SelectOption"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <CustomSelect
                      options={options}
                      onChange={onChange}
                      onBlur={onBlur}
                      ref={ref}
                      SelectOption={value}
                      setErrorMessage={setErrorMessage}
                      errors={errors}
                      styles={customStyles}
                      label={CONSTANTS?.PAYMENT_METHOD}
                    />
                  )}
                />

                <Container className={STYLES?.USER_MANAGEMENT_BUTTON}>
                  <Button
                    type="button"
                    className={STYLES?.CANCEL_BUTTON}
                    buttonText={CONSTANTS?.CANCEL}
                    onClick={hidePopUpForm}
                  />
                  <Button
                    type={CONSTANTS?.BUTTON_TYPE}
                    className={STYLES?.SEND_BUTTON}
                    buttonText={CONSTANTS?.ADD}
                    // onClick={EditForm}
                  />
                </Container>
              </form>
            </Container>
          </Container>
          {ErrorMessage && (
            <p className={STYLES?.Login_Error_Message}>{ErrorMessage}</p>
          )}
        </Container>
      </Container>
    </Container>
  );
};
export default UserManagementLogin;
