import React from "react";
import Container from "../../Atoms/Container/Container";
import { STYLES } from "../../../Helpers/Constants";
import SampleIcon from "../../Atoms/SampleIcon/SampleIcon";
import edLogo from "../../../Assets/LandingPageAssets/logo-ed.png";
import mgLogo from "../../../Assets/LandingPageAssets/logo-mg.png";
import cyLogo from "../../../Assets/LandingPageAssets/logo-cy.png";
import purLogo from "../../../Assets/LandingPageAssets/logo-pureI.png";
import rhLogo from "../../../Assets/LandingPageAssets/logo-rh.png";
import allLogo from "../../../Assets/LandingPageAssets/logo-all.png";
import tfLogo from "../../../Assets/LandingPageAssets/logo-TF.png";
import "./LogoBar.scss";

const LogoBar = () => {
  return (
    <Container className={STYLES?.LOGO_BAR_CONTAINER}>
      <SampleIcon src={edLogo} />
      <SampleIcon src={mgLogo} />
      <SampleIcon src={cyLogo} />
      <SampleIcon src={purLogo} />
      <SampleIcon src={rhLogo} />
      <SampleIcon src={allLogo} />
      <SampleIcon src={tfLogo} />
    </Container>
  );
};

export default LogoBar;
