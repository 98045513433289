import React, { useContext, useState } from "react";
import Container from "../../Atoms/Container/Container";
import { CONSTANTS, ROUTES, STYLES } from "../../../Helpers/Constants";
import { Text } from "../../Atoms/Typography/Text";
import Accordion from "../../Atoms/Accordion/Accordion";
import PrimaryButton from "../../Atoms/ButtonPrimary/ButtonPrimary";
import UploadFile from "../../Molecules/UploadFile";
import AccordionData from "../../Atoms/AccordionData";
import { useLocation, useNavigate } from "react-router-dom";
import CommentSection from "../../Atoms/CommentSection";
import { sendForReview } from "../../../Helpers/Functions";
import { errorToast, successToast } from "../../../Helpers/useToast";
import AppContext from "../../../Context/AppContext";

const AdminOrderDetails = () => {
  const { isLoading } = useContext(AppContext);

  const [comment, setComment] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const location = useLocation();
  const stateData = location.state;
  const navigate = useNavigate();

  const onSave = () => {
    if (stateData?.revisions?.length > 0) {
      if (comment !== "") {
        sendForReview(
          comment,
          stateData?.id,
          // stateData?.revisions[stateData?.revisions?.length - 1]?.document_url,
          fileUrl,
          console.log("New File Uploaded",stateData?.revisions[stateData?.revisions?.length - 1]?.document_url)
        ).then((res) => {
          navigate(ROUTES?.ORDER_MANAGEMENT);
          successToast("Order updated successfully");
        });
      } else {
        errorToast("Please add a comment");
      }
    } else {
      if (fileUrl && fileUrl !== "") {
        sendForReview(comment, stateData?.id, fileUrl).then((res) => {
          navigate(ROUTES?.ORDER_MANAGEMENT);
          successToast("Order sent successfully");
        });
      } else {
        errorToast("Please upload a document");
      }
    }
  };

  const handleCancel = () => {
    navigate(ROUTES?.ORDER_MANAGEMENT);
  };

  return (
    <Container className={STYLES?.ORDER_DETAILS_CONTAINER}>
      <Container className={STYLES?.ORDER_DETAILS_HEADER}>
        <Text>{CONSTANTS?.ORDER_DETAILS}</Text>
      </Container>
      <Accordion
        title={stateData?.page_type}
        innerElement={<AccordionData data={stateData} />}
        status
        isAdmin
        statusText={stateData.order_status}
      />
      <Accordion
        title={CONSTANTS?.UPLOAD_DOCUMENT}
        innerElement={
          <UploadFile
            fileUrl={fileUrl}
            setFileUrl={setFileUrl}
            previous={stateData?.revisions}
          />
        }
      />
      <Accordion
        title={CONSTANTS?.ALL_COMMENTS}
        innerElement={
          <CommentSection
            setComment={setComment}
            comments={stateData?.comments}
            textAreaDisabled={stateData?.order_status === "completed"}
          />
        }
      />
      <Container className={STYLES?.CREATE_ORDER_FORM_BUTTON}>
        <PrimaryButton
          className={STYLES?.SEND_BUTTON}
          buttonText={CONSTANTS?.SEND_REVIEW_TEXT}
          onClick={onSave}
          disabled={isLoading || stateData?.order_status === "completed"}
        />
        <PrimaryButton
          type="button"
          className={STYLES?.CANCEL_BUTTON}
          buttonText={CONSTANTS?.CANCEL}
          onClick={handleCancel}
          isCancel
        />
      </Container>
    </Container>
  );
};

export default AdminOrderDetails;
