import React, { useContext } from "react";

import EditIcon from "../../../Assets/edit.svg";
import "./SettingAccount.scss";
import { Text } from "../../Atoms/Typography/Text";
import AppContext from "../../../Context/AppContext";
import CustomLoader from "../../Atoms/CustomLoader/CustomLoader";

import Container from "../../Atoms/Container/Container";

const SettingAccount = ({ onClicked }) => {
  const { userProfile, isLoading } = useContext(AppContext);

  const rows = [
    { name: userProfile ? userProfile.name : "Anonymous", edit: true },
    { email: userProfile?.email, edit: false },
  ];

  return isLoading ? (
    <CustomLoader />
  ) : (
    <Container className="setting-account-container">
      <Container className="setting-account-title">
        <Text>User Profile</Text>
      </Container>

      <Container className="setting-account-details">
        {rows?.map((row, ind) => {
          return (
            <Container key={ind}>
              <Container className="setting-account-details-row">
                <Container className="setting-account-details-row-text">
                  <Text variant="p" className="setting-account-details-row-key">
                    {Object?.keys(row)[0]}
                  </Text>

                  <Text
                    variant="p"
                    className="setting-account-details-row-value"
                  >
                    {Object?.values(row)[0]}
                  </Text>
                </Container>

                {Object?.values(row)[Object?.values(row)?.length - 1] ? (
                  <Container
                    className="setting-account-details-row-edit"
                    onClick={() => onClicked(Object?.values(row)[0])}
                  >
                    <img src={EditIcon} alt="Edit" className="edit-icon" />
                  </Container>
                ) : null}
              </Container>
            </Container>
          );
        })}
      </Container>
    </Container>
  );
};

export default SettingAccount;
