import React from "react";
import Container from "../../Atoms/Container/Container";
import { CONSTANTS, STYLES } from "../../../Helpers/Constants";
import { Text } from "../../Atoms/Typography/Text";
import "./HeaderStyledText.scss";

const HeaderStyledText = () => {
  return (
    <Container className={STYLES?.HEADER_STYLED_TEXT_CONTAINER}>
      <Container className={STYLES?.GREY_STYLED_TEXT}>
        <Text>{CONSTANTS?.STAND_OUT_WITH}</Text>
      </Container>
      <Container className={STYLES?.GREEN_ITALIC_TEXT}>
        <Text>{CONSTANTS?.AUTHENTICITY}</Text>
      </Container>
      <Container className={STYLES?.SOLID_BLACK_TEXT}>
        <Text>{CONSTANTS?.HEADER_LONG_MESSAGE}</Text>
      </Container>
      <Container>
        <button className={STYLES?.GET_STARTED_BUTTON}>
          {CONSTANTS?.GET_STARTED}
        </button>
      </Container>
    </Container>
  );
};

export default HeaderStyledText;
