import React, { useEffect, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import "./PaymentMethod.scss";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import {
  CreateNewOrder,
  allPaymentMethods,
  attachPayment,
  startPayment,
  stripePayment,
  SavePayments,
} from "../../../Helpers/Functions";
import { defaultValues, schema } from "./FormSchema";
import { useNavigate } from "react-router-dom";
import { CONSTANTS, ROUTES, STYLES } from "../../../Helpers/Constants";
import { errorToast, successToast } from "../../../Helpers/useToast";
import RowSpace from "../../Atoms/RowSpace/RowSpace";
import Container from "../../Atoms/Container/Container";
import { Text } from "../../Atoms/Typography/Text";
import SavedPayment from "../../Molecules/SavedPayment/SavedPayment";
import InputField from "../../Atoms/InputField/InputField";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
// import AppContext from "../../../Context/AppContext";

const stripePromise = loadStripe("pk_live_EvYfmrWezzzSj4fLlEZixIoQ");

const PaymentMethod = ({ Data }) => {
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    stripePayment(Data?.page_type?.value).then((res) => {
      setClientSecret(res.client_secret);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const appearance = {
    rules: {
      ".Tab": {
        boxShadow: "none",
      },

      ".TabIcon--selected": {
        fill: "#059669",
      },

      ".TabLabel--selected": {
        color: "#059669",
      },

      ".Tab--selected": {
        border: "1px solid #059669",
        color: "#059669",
        boxShadow: "none",
      },

      ".Tab--selected:focus": {
        border: "1px solid #059669",
        boxShadow: "none",
        transition: "none",
      },
    },
  };

  const options = {
    clientSecret,
    appearance,
  };

  return (
    <>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm clientSecret={clientSecret} data={Data} />
        </Elements>
      )}
    </>
  );
};

function CheckoutForm({ data }) {
  const navigate = useNavigate();
  const {
    page_type,
    business_name,
    website_url,
    existing_url,
    content_examples,
    keywords,
    content_requirements,
    additional_notes,
  } = data;
  const amount = page_type.value;
  const pagetype = page_type.label;
  const stripe = useStripe();
  const elements = useElements();

  const [paymentMethods, setPaymentMethods] = useState({});

  const {
    register,

    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });
  const message = null;

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }
  }, [stripe]);

  const handleSubmit = async (event, cardDataId) => {
    event.preventDefault();
    if (!stripe || !elements) return;
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    if (error) {
      console.log(error.message);
    } else {
      const attachPaymentRes = await attachPayment(paymentMethod?.id);

      if (attachPaymentRes) {
        const makePaymentRes = await startPayment(
          cardDataId || paymentMethod?.id,
          data?.page_type?.value
        );
        const responseData = await CreateNewOrder({
          page_type: pagetype,
          price: amount,
          business_name: business_name,
          website_url: website_url,
          existing_url: existing_url,
          content_examples: content_examples,
          content_requirements: content_requirements,
          keywords: keywords,
          additional_notes: additional_notes,
        });
        const savePayment = await SavePayments(
          makePaymentRes.id,
          responseData.id,
          parseInt(amount)
        );
        if (savePayment) {
          // setIsLoading(false);

          navigate(ROUTES?.ORDER);
          successToast("New order submitted successfully");
        } else {
          errorToast("An unexpected error occurred.");
          // setIsLoading(false);
        }
      } else {
        errorToast("An unexpected error occurred.");

        // setIsLoading(false);
      }
    }
  };

  const cardElementStyle = {
    style: {
      base: {
        backgroundColor: "#FFFFFF",
        color: "#000000",
        fontWeight: "500",
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "17px",
        borderRadius: "4px",

        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#000000",
        },
        "::placeholder": {
          color: "gray",
          placeholder: "00000000",
        },
      },
    },
  };

  const handleCardClick = async (cardData) => {

    const makePaymentRes = await startPayment(
      cardData?.id,
      data?.page_type?.value
    );
    const responseData = await CreateNewOrder({
      page_type: pagetype,
      price: amount,
      business_name: business_name,
      website_url: website_url,
      existing_url: existing_url,
      content_examples: content_examples,
      content_requirements: content_requirements,
      keywords: keywords,
      additional_notes: additional_notes,
    });
    const savePayment = await SavePayments(
      makePaymentRes.id,
      responseData.id,
      parseInt(amount)
    );
    if (savePayment) {
      navigate(ROUTES?.ORDER);

      successToast("New order submitted successfully");
    } else {
      errorToast("An unexpected error occurred.");
    }
  };
  useEffect(() => {
    allPaymentMethods().then((response) => {
      setPaymentMethods(response);
    });
  }, []);
  return (
    <>
      <form id="payment-form" onSubmit={handleSubmit}>
        <Container className={STYLES?.PAYEMNT_DETAILS}>
          <Container className={STYLES?.PRICE_TAG}>
            <Text className={STYLES?.PRICE_TAG_TEXT}>{CONSTANTS?.PRICE}</Text>
            <Text className={STYLES?.PRICE_TAG_GREEN_TEXT}>{`${"$"}${
              data?.page_type?.value
            }${".00"}`}</Text>
          </Container>
        </Container>
        <RowSpace margin={20} />
        <RowSpace margin={20} />
        {paymentMethods?.data?.length > 0 ? (
          <SavedPayment
            paymentMethods={paymentMethods?.data}
            clickable={true}
            onCardClick={handleCardClick}
            deleteText={""}
          />
        ) : (
          <>
            <InputField
              name={CONSTANTS?.NAME}
              type={CONSTANTS?.TEXT}
              field={CONSTANTS?.NAME}
              placeholder={""}
              register={register}
              errors={errors}
              label={CONSTANTS?.NAME_ON_THE_CARD}
              className={STYLES?.PAYMENT_INPUT_FIELD}
            />
            <RowSpace margin={40} />
            <label>{CONSTANTS?.CARD_DETAILS}</label>
            <RowSpace margin={20} />
            <Container className={STYLES?.CUSTOM_CARD_INPUT_FIELD}>
              <CardElement options={cardElementStyle} />
            </Container>

            <RowSpace margin={60} />

            <button disabled={!stripe || !elements} id="submit">
              <span id="button-text">Pay now</span>
            </button>
          </>
        )}
        {message && <div id="payment-message">{message}</div>}
      </form>
    </>
  );
}
export default PaymentMethod;
