export const CONSTANTS = {
  ADD_COMMENT: "Add comment",
  ADD_INVOICE: "Add Invoice",
  ADD_CONTENT: "Add another content examples",
  AMOUNT: "Amount",
  ALL_RIGHTS_TEXT: "©2024 wordwell. All rights reserved",
  ALL_COMMENTS: "All Comments",
  AUTHENTICITY: "WordWell ",
  ALREADY_HAVE_ACCOUNT: "Already have a business account? ",
  ARTICLES: "Articles",
  ARTICLES_DETAILS:
    "Well-researched, thoroughly engaging articles tailored to your brand’s voice and audience.",

  BLOG_POST_TITLE: "Blog Posts",
  BLOG_DETAILS:
    "Engage your audience with compelling, insightful blog content that drives traffic and enhances SEO.",
  BILLING_SETTING: "Billing",
  CARD_DETAILS: "Card Details",
  DUE_DATE: "Due Date",
  DOWNLOAD_FILE: "Download File",
  FORGET_PASSWORD_TITLE: "Forgot your password?",
  FULL_NAME: "Full name",
  PAYMENT_METHOD_DELETED: "Payment Method deleted successfully",
  UPLOAD_DOCUMENT: "Upload a document",
  UPLOAD_FILE: "Upload the file",
  UPLOAD_FILE_TYPES: "XLS, Doc, CSV up to 10MB",
  UPLOAD_A_FILE: "Upload a file",
  USER: "User",
  DRAG_DROP_TEXT: "or drag and drop",
  BANK_TRANSFER: "Bank transfer",
  CANCEL: "Cancel",
  DASHBOARD: "Dashboard",
  ENTER_CARD_NUMBER: "Enter Card Number",
  CREATE_PASSWORD: "Create a password",
  COOKIES_SETTINGS: "Cookies Settings",
  DELETE: "Delete",
  ORDER_DELETE_CONFIRMATION_TEXT: "Are you sure you want to delete this order?",
  USER_DELETE_CONFIRMATION_TEXT: "Are you sure you want to delete this user?",
  BUTTON_TYPE: "submit",
  BLOG_POST: "Blog Post (400-600 words)",
  PAGE_BLOG_NAME_POST: "Page/Blog Name",
  CORRECT: "Wrong email address entered",
  CHECKBOX: "checkbox",
  CARD_NUMBER: "Card number",
  CVV: "CVC code",
  EMAIL: "Email",
  EMAIL_TYPE: "email",
  EMAIL_ADDRESS: "Email address",
  WEBSITE_URL: "Website URL",
  IF_REWRITE_PROVIDE_LINK_TO_EXISTING_PAGE:
    "If rewrite, provide link to existing page",
  CONTENT_EXAMPLE: "Content Examples - Share URLS",
  CONTENTEXAMPLE: "ContentExamples",
  COMMON_ERROR: "Something went wrong",
  KEYWORDS: "Keywords",
  ADDITIONAL_NOTES: "Additional notes",
  CONTENT_REQUIREMENTS: "Content requirements",
  CODE_EXPIRE_TEXT: "Your code will expire in ",
  CODE_NOT_RECEIVED: "Code not received? ",
  RESEND: "RESEND",
  EFFECT_COMM: "effective communication",
  EMAIL_PLACEHOLDER: "you@example.com",
  EMAIL_VALUE: "Enter an Email",
  EMAIL_MAX: "Email is too long",
  EMAIL_MIN: "Email is too short",
  ENDING_WITH: " ending with ",
  EXP_DATE: "Exp date",
  FORGET_PASSWORD: " Forgot your password?",
  FULL_WEB: "Full Website Copy",
  FULL_WEB_DETAILS:
    "Comprehensive website copywriting services to ensure consistency and quality across your entire site.",
  GET_STARTED: "Get Started Today",
  HEADER_LONG_MESSAGE:
    "WordWell provides a comprehensive content creation solution for publishers and marketing agencies with content management and distribution built into the process",
  INVOICE_LINK: "InvoiceLink",
  INNO_APPROACH_TO: "Innovative Approach to",
  INVOICE_LINK_LABEL: "Invoice link",
  IT_WILL_COST: "It will cost",
  LOGIN_TEXT: "Log in to your account",
  LOGIN: "Log in",
  LINK_TO_PAY: "Link to pay",
  LONG_INTRO:
    "In a world increasingly dominated by artificial intelligence, the unique human touch becomes invaluable. At WordWell, we believe that truly effective communication stems from genuine human interaction and creativity. Our human writers understand subtleties and emotions that AI simply can’t replicate, ensuring that your content resonates deeply with your audience. From establishing trust to enhancing brand identity, our human-crafted content connects on a level that automated systems cannot achieve, making every word count in building lasting relationships with your readers.",
  ORDER_DETAILS: "Order Details",
  OPTIONAL: " (Optional)",
  PAYMENT_DETAILS: "Payment Details",
  PASSWORD: "Password",
  PASSWORD_TYPE: "password",
  REMEMBER_ME: "Remember me",
  PASSWORD_VALUE: "Enter a password",
  PASSWORD_MIN: "Password must be at least 6 characters",
  PASSWORD_MAX: "Password is too long",
  PASSWORD_CHANGED_SUCCESS:
    "Your password has been set successfully. You have been redirected to the login screen",
  PAY_NOW: "Pay now",
  PAYMENT_INFORMATION: "Payment Information",
  PRICE: "Price",
  PRIVACY_POLICY: "Privacy Policy",
  NAME_CAPITAL: "Name must be capital",
  NAME_VAILD: "Name should  be vaild for email",
  NAME_ON_THE_CARD: "Name on the card",
  NEXT_INVOICE: "Next Invoice date",
  NEW_PAYMENT_METHOD: "New Payment method",
  ORDER_DETAILS_LABEL: "New order creation",
  ORDER_DETAILS_KEY: "Order details",
  ORDER_COLUMN: "Order",
  OUR_SERVICES: "Our Services",
  WORDWELL_SLOGAN: "Real Writers, Real Results",
  WORDWELL_MESSAGE:
    "You want your content to resonate with a real human audience. That begins with talented human writers.The rise of AI generated content has saturated the market with content creation services. Unfortunately, it has also significantly reduced the scope of originality and customization of web content, proving that even as AI technology becomes more commonplace, there’s simply no substitute for the human perspective. With WordWell, you will always get 100% human written content that goes through rigorous quality assurance. We take the headaches out of searching for qualified writers and keep you from settling for formulaic AI content. Plus, we make it incredibly easy to order, purchase, review, approve, and publish your content with a streamlined ordering platform. ",
  MISSION_STATEMENT:
    "Our mission is to empower agencies, publishers, and business owners to achieve their goals with superior, human-written content that is secure and copyright-protected, standing out in a world of AI-generated text, and continuing to let human writers thrive.",
  SAVED_PAYMENT: "Saved Payment",
  SIGN_UP: "Sign Up",
  SIGN_IN: "Sign in",
  SUBMIT: "Submit",
  SEND_REVIEW_TEXT: "Send for a review",
  SERIAL_NO: "S.NO",
  SELECT_USER: "Choose Client",
  SIGN_UP_TEXT: "Sign up and create your account",
  TERMS_OF_SERVICE: "Terms of Service",
  WHY_HUMAN_CRAFTED: "Why Human-Crafted Content Matters",
  SIGN_UP_PROMPT_TEXT: "Don't have a business account? ",
  STAND_OUT_WITH: "Exceptional Content Creation",
  TOTAL_ORDERS: "Total Orders",
  IN_PROGRESS: "In Progress",
  COMPLETED: "Completed",
  FORGET_PASSWORD_TEXT:
    " Enter your email address and we'll send you a link to reset your password.",
  RESET_PASSWORD: "Reset Password",
  PASSWORD_LABEL: "Password",
  NEW_PASSWORD: "New Password",
  CONFIRM_PASSWORD: "Confirm password",
  PASSWORD_DONT_MATCH: "Passwords do not match!",
  CONFIRM: "CONFIRM",
  CONFIRMPASSWORD: "confirmPassword",
  USER_MANAGEMENT: "User Management",
  USER_ADDED_TEXT: "New user added successfully.",
  USER_DELETED_TEXT: "User has been deleted successfully.",
  UNLEASH_AUTH: "Unleash Authenticity",
  YOUR_PROFILE: "Your Profile",
  INVOICE_ADDED_TEXT: "New invoice added successfully.",
  ORDER_MANAGEMENT: "Order Management",
  BILLING: "Billings",
  SETTING: "Settings",
  ORDER: "My Orders",
  MY_ORDER: " My orders",
  ClIENT_NAME: "Client name",
  NAME: "Name",
  NAME_MISSING: "Name is missing",
  ORDER_TYPE: "Order type",
  DATE: "Date",
  EMAIL_ADRESS: "Email address",
  CREATED_ON: "Created on",
  PAYMENT_METHOD: "Payment method",
  STATUS: "Status",
  DOWNLOAD: "Download",
  NO_ORDER: "No order",
  SEARCH: "Search",
  NO_ORDER_RECIEVED_YET: "No order recieved yet!",
  NO_ORDER_ADDED_YET:
    "Welcome to WordWell.\nGet started with your first order!",
  OTP: "OTP",
  OTP_ENTER: "Enter the OTP you received on",
  OTP_MAX: "OTP is too long",
  ACTION: "Action",
  SIGNOUT: "Sign out",
  NEW_WORDWELL_USER: "Create new wordwell user",
  CHANGE_NAME: "Change name",
  UPDATE_WORLDWELL_USER: "Update wordwell user",
  STRIPE: "Stripe",
  QUICK_BOOKS: "Quickbooks",
  SEND: "Send",
  CREATE_NEW_USER_BUTTON: "Create new user",
  DEBIT_CARD: "Debit card",
  CREDIT_CARD: "Credit card",
  INVITED: "Invited",
  ACCEPTED: "Accepted",
  SELECT_ERROR: "Select the options",
  ADD: "Add",
  SelectOPTION: "SelectOption",
  FILTER: "Filter",
  FORGET_PASSWORD_LINK_EXP: "Your link is Expired",
  NO_USER: "No User",
  CREATE_NEW_USER: "Create new user",
  LEARN_MORE: "Learn More",
  MARKTING_ENTHUSIASTS: "We are content marketing Enthusiasts",
  CREATE_NEW_PASSWORD: "Create a new password",
  NEXT: "Next",
  SORT: "Sort",
  ERROR_MESSAGE_API_FAIL: "You have entered an invalid email or password",
  FORGOT_PASSWORD_RESET:
    "A new request for password has been sent to your email address.",
  CREATE_NEW_ORDER: "Create new order",
  UPDATE_PAYMENT_METHOD: "Update payment Method",
  USER_PROFILE_SETTINGS: "User Profile",
  ZIP_CODE: "ZIP code",
  REVIEW: "Review",
  PAGE_TYPE: "Page Type",
  PAGE_BLOG_NAME: "PageBlogName",
  BUSINESS_NAME: "Business Name",
  TEXT: "text",
  BUSINESS_NAME_FROM: "BusinessName",
  WEBSITE_URL_FORM: "WebsiteURL",
  WEB_CONTENT: "Website Content",
  WEB_CONTENT_DETAILS:
    "From landing pages to about sections, get custom-written content that captivates visitors and conveys your brand’s message.",
  EXISTING_URL: "ExistingUrl",
  CONTENT_REQUIREMENTS_FORM: "ContentRequirements",
  CONTENT_EXAMPLES_FORM: "ContentExamples",
  DESCRIPTION: "Description",
  COMMENT_TEXT_AREA: "Write a comment...",
  DESCRIPTION_MAX: "Description is too long",
  TEXTAREA: "textareaField",
  BACK: "Back",
  BILLING_DATE: "Billing date",
  OTP_VERIFICATION: "OTP Verification",
  INVOICES: "Invoices",
  INVOICE_TOTAL: "Invoice total",
  NO_INVOICE: "No Invoices",
  NO_INVOICES_YET: "No Invoices added yet!",
  NAME_MAX: "Name is too long",
  CREATE_NEW_INVOICE: "Create new Invoice",
  APPROVE: "Approve",
  ADD_REVISION: "Request Revision",
};
export const STYLES = {
  LOGIN_FORM_ERROR: "login-form-errors",
  ALL_RIGHTS_RESERVED: "all-rights-reserved",
  Action_Column_BUTTON: "action-column-button",
  ACCORDION_DATA_TITLE: "accordion-data-title",
  ACCORDION_DATA: "accordion-data",
  ACCORDION_DATA_CONTAINER: "accordion-data-container",
  ACCORDION_DATA_INNER_CONTAINER: "accordion-data-inner-container",
  ACCORDION_DATA_BODY: "accordian-data-body",
  ADMIN_LOGIN_PAGE: "admin-login-page",
  ADD_FIELD_BUTTON: "add-field-button",
  AESTERISK: "asterisk",
  BILLING_SETTING_CONTAINER: "billing-setting-container",
  BORDER: "border",
  BUTTON_CARD_INNER_CONTAINER: "button-card-inner-container",
  BILLING_DOWNLOAD_BUTTON: "billing-download-button",
  BILLING_SETTING_HEADER: "billing-setting-header",
  BILLING_SETTING_NAME_ACTIVE: "active-billing-setting-name",
  BILLING_SETTING_NAME: "billing-setting-name",
  BOLD_GREEN_TEXT: "bold-green-text",
  BLACK_BOLD_HEADING: "black-bold-heading",
  BOTTOM_BG_CONTAINER: "bottom-bg-container",
  BOTTOM_LINKS: "bottom-links",
  BUTTON_CONTAINER: "button-container",
  BOTTOM_CONTENT_CONTAINER: "bottom-content-container",

  BOTTOM_CONTAINER: "bottom-container",
  NORMAL_BLACK_TEXT: "normal-black-text",
  CARD_HEADER: "card-header",
  CARD_HEADER_TEXT: "card-header-text",
  CARD_TOTAL: "card-total",
  CARD_IN_PROGRESS: "card-in-progress",
  CARD_INVOICE_TOTAL: "card-invoice-total",
  CARD_COMPLETE: "card-complete",
  CARD_DATE: "card-date",
  CARD_ICON: "card-icon",
  CARD_INFO: "card-info",
  CUSTOM_CARD_INPUT_FIELD: "custom-card-input-field",
  CURSOR_POINTER: "cursor-pointer",
  CLICKABLE_CONTAINER: "clickable-container",
  COMMENTS_CONTAINER: "comments-container",
  COMMENTS_USER_DETAILS: "comments-user-detail",
  COMMENTS_CONTENT: "comments-user-content",
  COMMENT_SECTION: "comment-section",
  COMMENT_PROFILE: "comment-profile",
  CONTENT_SAMPLE_CONTAINER: "content-sample-container",
  CONTENT_SAMPLE_INNER_CONTAINER: "content-sample-inner-container",
  CONTENT_SAMPLE_BG_CONTAINER: "content-sample-bg-container",
  CONTENT_BAR: "content-bar",
  CUSTOM_SELECT_CONTAINER: "custom-select-container",
  CLIENT_SEARCH_CONTAINER: "client-search-container",
  CHANGE_NAME_PAGE: "change-name-page",
  CATEGORY_TEXT: "category-text",
  CREATE_ORDER_STEPPER: "create-order-stepper",
  DELETE_MESSAGE_CONTAINER: "delete-message-container",
  DELETE_MESSAGE_BOX: "delete-message-box",
  DELETE_MESSAGE_HEADER: "delete-message-header",
  DELETE_MESSAGE_FOOTER: "delete-message-footer",
  DELETE_MESSAGE_LOGO: "delete-message-logo",
  DELETE_MESSAGE_HEADER_TEXT: "delete-message-header-text",
  DELETE_MESSAGE_TEXT: "delete-message-text",
  DELETE_CONFIRMATION_TEXT: "delete-confirmation-text",
  DELETE_BUTTON: "delete-button",
  DELETE_CANCEL_BUTTON: "delete-cancel-button",
  DROP_DOWN_CONTENT_SINGLE: "dropdown-content-single",
  DROP_DOWN_CONTENT_EXTENDED: "dropdown-content-extended",
  DOWNLOAD_BUTTON: "download-button",
  DRAG_DROP: "drag-and-drop-text",
  DOT: "status-dot",
  FOOTER_LINK_CONTAINER: "footer-links-container",
  EMAIL_TEXT: "email-text",
  ORDER_DETAILS_HEADER: "order-details-header",
  GREY_NORMAL_TEXT: "grey-normal-text",
  SEARCH_CONTAINER: "search-field",
  All_Rights_Reserved: "all-rights-reserved",
  ERROR_MESSAGE: "error-message",
  Eye_Icon: "eye-icon",
  FORM_BUTTON: "form-button",
  FORGOT_BUTTON: "forgot-button",
  FOOTER_CONTAINER: "footer-container",
  FOOTER_CONTENT: "footer-content",
  GREEN_ITALIC_TEXT: "green-italic-text",
  GET_STARTED_BUTTON: "get-started-button",
  GREEN_STYLED_MED_TEXT: "green-styled-medium-text",
  HEADER_BG_CONTAINER: "header-bg-container",
  HEADER_CONTAINER: "header-container",
  HEADER_LOGO: "header-logo",
  HEADER_LOGO2: "header-logo2",

  HEADER_LOGIN_BUTTON: "header-login-button",
  HEADER_SIGNUP_BUTTON: "header-signup-button",
  HEADER_STYLED_TEXT_CONTAINER: "header-styled-text-container",
  HEADER_BUTTON_CONTAINER: "header-button-container",
  HEADER_TEXT_CONTAINER: "header-text-container",
  INPUT_FIELD: "input-field",
  INPUT_FIELD_ERROR: "input-form-field-error",
  LOGIN_FORM_HEADER: "login-form-header",
  USER_LOGIN_FORM_HEADER: "user-login-form-header",
  LANDING_PAGE_HEADER_BG: "landing-page-header-bg",
  LARGE_BLACK_PLAYFAIR: "large-black-playfair",
  LOGIN_PAGE: "login-page",
  LOGIN_PAGE_CLIENT: "login-page-client",
  LOGIN_FORM_CONTAINER: "login-form-container",
  LOGIN_FORGET_ERROR: "login-form-errors-forgot-pass",
  LOGIN_FORGET: "login-form-forgot-pass",
  LIGHT_GREY_TEXT: "light-grey-text",
  LIGHT_GREY_BOX: "light-grey-box",
  LIGHT_GREY_BOX_HEADING: "light-grey-box-heading",
  LIGHT_BLACK_TEXT: "light-black-text",
  LOGO_BAR_CONTAINER: "logo-bar-container",
  LOGO_FOOTER: "logo-footer",
  INPUT_FIELD_FORGET_ERROR: "input-form-field-error-forgot-pass",
  INPUT_FILED_FORGET: "input-form-field-forgot-pass",
  INTRO_BACKGROUND: "intro-background",
  INTRO_TEXT: "intro-text",
  INTRO_IMG: "intro-image",
  LOGO_FORGET: "logo-forgot-pass",
  LOGIN_FORGET_CONTAINER: "login-container-forgot-pass",
  ORDER_IP: "order-in-progress",
  ORDER_STATUS_CONTAINER: "order-status-container",
  ORDER_FORM_CONTAINER: "order-form-container",
  RESET_FORGET_PASSWORD: "reset-password-email",
  FORGET_PASSWORD_BOTTOM_TEXT: "forgot-pass-bottom-text",
  LOGIN_CREATE_PASSWORD_ERROR: "login-form-errors-create-pass",
  CREATE_LOGIN_PASSWORD: "login-form-create-pass",
  CREATE_NEWPASSWORD_CONTAINER: "create-newpass-container",
  LOGIN_TEXT: "login-text",
  Login_Error_Message: "login-error-message",
  LOGIN_FORM: "login-form",
  LABEL: "label",
  MONOGRAM_CONTAINER: "monogram-container",
  NEW_PASSWORD_INNER_CONTAINER: "new-password-inner-container",
  ORDER_DETAILS_CONTAINER: "order-details-container",
  OTP_CONTAINER: "otp-container",
  OTP_INNER_CONTAINER: "otp-inner-container",
  PAYMENT_METHOD_CONTAINER: "payment-method-container",
  PAYMENT_OPTIONS_CONTAINER: "payment-options-container",
  PAYMENT_OPTIONS_ICON: "payment-options-icon",
  PAYMENT_OPTIONS: "payment-options",
  PAYMENT_OPTIONS_TEXT: "payment-options-text",
  PAYMENT_PRICE: "payment-price",
  PAYMENT_INPUT_FIELD: "payment-input-field",
  PAYMENT_FORM_FOOTER: "payment-from-footer",
  PAYMENT_METHOD_BUTTON: "payment-method-button",
  PAYMENT_SELECTED: "payment-selected",
  PAYEMNT_DETAILS: "payment-details",
  PAYMENT_INFO_TEXT: "payment-info-text",
  PAYMENT_INFO: "payment-info",
  PAY_NOW_BUTTON: "pay-now-button",
  PAYMENT_METHOD_CELL: "payment-method-cell",
  PRICE_TAG: "price-tag",
  PRICE_TAG_TEXT: "price-tag-text",
  PRICE_TAG_GREEN_TEXT: "price-tag-green-text",
  PRODUCT_SAMPLE_CONTAINER: "product-sample-container",
  PRODUCT_SAMPLE_INNER_CONTAINER: "product-sample-inner-container",
  PRODUCT_TITLE: "product-title",
  PRODUCT_DETAILS_TEXT: "product-detail-text",
  REGULAR_BLACK_TEXT: "regular-black-text",
  REQUIRED_FIELD: "required-field::before",
  User_Login_Form: "user-login-form",
  LOGIN_INPUT_FIELD: "login-input-field",
  LANDING_PAGE_MAIN_CONTAINER: "landing-page-main-container",
  REMEMBER_ME: "remember-me",
  SAVED_PAYMENT_INNER_CONTAINER: "saved-payment-inner-container",
  SIGNIN_FORM_SUBMIT: "signin-form-submit",
  TEXT_VARIANT_H5: "h5",
  LOGO_IMG: "LogoImg",
  SIDEBAR_ICON: "SideBarIcon",
  SIDEBAR: "Sidebar",
  SIDEBAR_MEDIUM: "sidebar-medium",
  SIMPLE_GREEN_TEXT: "simple-green-text",
  SIMPLE_GREY_TEXT: "simple-grey-text",
  SOLID_BLACK_TEXT: "solid-black-text",
  SMALL_WHITE_TEXT: "small-white-text",
  SMALL_ICON: "small-icon",
  SMALL_WHITE: "small-white-text",
  SMALL_GREEN_ROBOTO: "small-green-roboto",
  SOCIAL_LINKS_CONTAINER: "social-links-container",
  NAVBAR: "Navbar",
  SIDEBAR_ITEM: "SidebarItem",
  ACTIVE_LINK: "ActiveLink",
  ACTIVE_TEXT: "ActiveText",
  SEARCH: "Search",
  SEARCH_FIELD: "SearchField",
  SEARCH_HEADING: "search-heading",
  GREY_STYLED_TEXT: "grey-styled-text",
  INPUTFILED: "InputField",
  SEARCH_ICON: "SearchIcon",
  SETTING_ACCOUNT_DETAILS: "setting-account-details",
  SETTING_ACCOUNT_CONTAINER: "setting-account-container",
  SMALL_BLACK_TEXT: "small-black-text",
  SMALL_BLACK_TEXT_SIMPLE: "small-black-text-simple",
  TOGGLE_BUTTON: "toggle-button",
  USER_BILLING_PAGE: "user-billing-page",
  UPLOAD_FILE_MAIN_CONTAINER: "upload-file-main-container",
  UPLOAD_FILE_CONTAINER: "upload-file-container",
  UPLOAD_FILE_INNER: "upload-file-inner",
  UPLOAD_FILE_CONTENT: "upload-file-content",
  UPLOAD_FILE_ICON: "upload-file-icon",
  UPLOAD_FILE_TYPE: "upload-file-type",
  UPLOAD_TEXT: "upload-text",
  UPLOAD_FILE_BOX: "upload-file-box",
  UPLOADED_FILE_INFO: "uploaded-file-info",
  UPLOAD_HEAD_TEXT: "upload-head-text",
  UPLOADED_FILE_NAME_CONTAINER: "uploaded-file-name-container",
  DRAG_OVER: "drag-over",
  PREVIOUS_FILE_CONTAINER: "previous-file-container",
  PREVIOUS_FILE_INNER_CONTAINER: "previous-file-inner-container",
  LOGO: "logo",
  LogoImg: "LogoImg",
  SidebarIcon: "SideBarIcon",
  Siderbar: "Sidebar",
  Navbar: "Navbar",
  SideBarItem: "SidebarItem",
  ActiveLink: "ActiveLink",
  ActiveText: "ActiveText",
  RESEND_BUTTON: "resend-button",
  RESEND_BUTTON_DISABLED: "resend-button-disabled",
  Search: "Search",
  SearchField: "SearchField",
  InputField: "InputField",
  SearchIcon: "SearchIcon",
  MainContent: "main-content",
  DISPLAY_SCREEN: "display-screen",
  CONTENT_AREA: "content",
  LAYOUT_CONTENT: "layout",
  BREADCRUMBS: "breadcrumbs",
  BRREADCRUMBS_LINK: "breadcrums-link",
  USER_PROFILE: "user-profile",
  PROFILE_DETAIL: "profiledetail",
  PROFILE_NAME: "profile-name",
  LOGOUT: "logout",
  SORT_BOX_CONTAINER: "sort-box-container",
  SORT_BOX_INNER_CONTAINER: "sort-box-inner-container",
  SORT_BOX_TEXT: "sort-box-text",
  CUSTOM_SORT: "custom-sort",
  Input_Field_Container: "input-field-container",
  Deals_Details: "deals_details",
  Deal_Details_text: "deal-details-text",
  Link_Text: "link-text",
  LOGIN_USER_MANAGEMENT: "user-management-login",
  USER_MANAGEMENT_LOGIN_TEXT: "user-management-login-text",
  SEND_BUTTON: "send-button",
  SUBMIT_BUTTON: "submit-button",
  CANCEL_BUTTON: "cancel-button",
  SIGN_UP_FORM_PARENT: "sign-up-form-parent",
  SIGN_UP_FORM_HEADER: "user-sign-up-form-header",
  SIGN_UP_PAGE: "sign-up-page",
  SIGN_UP_BOTTOM: "sign-up-page-bottom-text",
  SAVED_PAYMENT_CONTENT: "saved-payment-content",
  TEXT_AREA: "text-area",
  TEXT_AREA_TITLE: "text-area-title",
  USER_TITLE_TEXT: "user-title-text",
  USER_MANAGEMENT_LOGIN_FORM_ERROR: "user-management-login-form-errors",
  USER_MANAGEMENT_BUTTON: "user-management-button",
  USER_MANAGEMENT_INPUT_FIELD: "user-management-input-field",
  USER_LOGIN_PAGE: "user-login-page",
  USER_LOGIN_FORM_PARENT: "user-login-form-parent",
  USER_LOGIN_FORM_BACKGROUND: "user-login-page-background",
  USER_LOGIN_PAGE_OVERLAY: "user-login-page-overlay",
  USER_ORDER_PAGE: "user-order-page",
  USER_ORDER_CARD_CONTAINER: "user-order-card-container",
  USER_ORDER_CARD: "user-order-card",
  USER_ORDER_CARD_HEADER: "user-order-card-header",
  USER_ORDER_CARD_FOOTER: "user-order-card-footer",
  WHITE_STYLED_TEXT: "white-styled-text",
  CREATE_USER_BUTTON: "create-new-user-button",
  CREATE_USER_ORDER_BUTTON: "create-new-order-button",
  INPUT_FORM_FIELD_ERROR_USER_MANAGEMENT:
    "input-form-field-error-user-management",
  CROSS_ICON: "cross-icon",
  USER_MANAGEMENT_POPUP: "user-management-poppup",
  USER_MANAGEMENT_LOGIN_PAGE: "user-management-login-page",
  FIILTER_IMG: "Filter_img",
  USER_MANAGEMENT_SCREEN: "user-management-screen",
  SEARCH_CONTENT: "search-content",
  TOP_HEADER_BUTTON: "button-header",
  BOTTOM_CREATE_BUTTON: "bottom-create-button",
  CUSTOM_TABLE: "custom-table",
  SELECTED_OPTIONS: "selected-option",
  OPTIONS_CONTAINER: "options-container",
  CUSTOM_OPTIONS: "custom-options",
  OPTIONS_VALUE: "options-value",
  OPTIONS: "options",
  ACTIVE_OPTIONS: "active-option",
  CREATE_NEW_USER_CONTAINER: "create-new-hot-deal-message-container",
  NEW_USER_TAG_ICON: "hotdeal-tag-logo ",
  NO_USERS: "no-deals",
  LEARN_MORE: "learn-more",
  INPUT_FORM: "input-form-field",
  CREATE_PASSWORD_TITLE: "create-new-password-title",
  FORGOT_PASSWORD_HEADER: "forgot-password-header",
  SORT_OPTIONS: "sort-option",
  FORGORT_PASSWORD_USER: "forgot-pass-user",
  SELECT: "select",
  CREATE_PRIMARY_BUTTON: "create-primary-button",
  CREATE_NEW_PASSWORD_USER: "create-new-pass-user",
  CREATE_ORDER_FORM: "create-order-form",
  CREATE_NEW_ORDER_FORM_TITLE: "create-new-order-fomr_title",
  ORDER_DETAILS_FROM_LINK: "order-details-link",
  ORDER_INFORMATION: "order-information",
  ORDER_VIEW_KIND: "order-view-kind",
  TEXTAREA_CREATE_NEW_ORDER: "text-area-create",
  TEXTAREA_CREATE_ERROR: "text-area-create-error",
  CREATE_NEW_ORDER_FORM: "create-new-order-form",
  CREATE_ORDER_FORM_BUTTON: "create-order-form-button",
  ACTIVE_STEP: "active-step",
  REVIEW_CONTAINER: "review-container",
  ORDER_REVIEW_TEXT: "order-review-text",
  CREATE_ORDER_HEADING: "create-order-heading",
  REVIEW_BUTTON: "review-button",
  NOT_FOUND_TITLE: "not-found-title",
};
export const FONT = {
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
  XXXLARGE: "xxxlarge",
  REGULAR: "regular",
  BOLD: "bold",
  BASE: "base",
};
export const COLOR = {
  GRAY: "gray",
  GREEN: "green",
  GRAY0: "gray-00",
  GRAY1: "gray-1",
  GRAY2: "gray-2",
  Gray3: "gray-3",
  CARD_GRAY: "card-gary",
};
export const ROUTES = {
  BILLING: "/admin/billing",
  USER_MANAGEMENT: "/admin/user-management",
  ORDER_MANAGEMENT: "/admin/order-management",
  ORDER_DETAILS: "/order-details",
  ORDER: "/user/order",
  SETTING: "/admin/setting",
  USER_SETTING: "/user/setting",
  USER_BILLING: "/user/billing",
  USER_LOGIN: "/user/login",
  USER_SIGN_UP: "/user/sign-up",
  ADMIN_LOGIN: "/admin/login",
  FORGOT_PASSWORD: "/forgot-password",
  USER_FORGOT_PASSWORD: "/user/forgot-password",
  CREATE_NEW_PASSWORD: "/create-new-password",
  CREATE_NEW_ORDER: "/create-new-order",
  CREATE_NEW_ORDER_FORM: "/create-new-order-form",
  LANDING_PAGE: "/landing-page",
};

export const OrderStatuses = {
  neworder: { text: "In Progress", admintext: "New Order" },
  inprogress: { text: "In Progress", admintext: "In Progress" },
  received: { text: "Received", admintext: "Submitted" },
  revise: { text: "Revision needed", admintext: "Asked for Revision" },
  completed: { text: "Completed", admintext: "Completed" },
  accepted: { text: "Accepted", admintext: "Accepted" },
  signedup: { text: "Signed Up", admintext: "Signed Up" },
  invited: { text: "Invited", admintext: "Invited" },
  paid: { text: "Paid", admintext: "Paid" },
  unpaid: { text: "Unpaid", admintext: "Unpaid" },
};

export const PaymentMethods = {
  quickbooks: "Quickbooks",
  stripe: "Stripe",
};
