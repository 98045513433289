import React, { useEffect } from "react";
import { useState } from "react";
import Container from "../../Atoms/Container/Container";
import { CONSTANTS, STYLES } from "../../../Helpers/Constants";
import BoldBlackHeading from "../../Atoms/BoldBlackHeading/BoldBlackHeading";
import ClientSerach from "../../Molecules/ClientSearch/ClientSearch";
import { PaymentMethods, PaymentStatus } from "../../../Helpers/FilterOptions";
import RowSpace from "../../Atoms/RowSpace/RowSpace";
import CreateNewUser from "../../Molecules/CreateNewUser/CreateNewUser";
import DealsTableTitleRow from "../../Atoms/DealsTableTitleRow";
import DataTable from "react-data-table-component";
import { UserBillingColumn, customStyles } from "./UserBillingColumn";
import CustomPagination from "../../Molecules/CustomPagination/CustomPagination";
import CustomLoader from "../../Atoms/CustomLoader/CustomLoader";
import "./UserBilling.scss";
import UserBillingCards from "../../Molecules/UserBillingCards";
import {
  formatFilter,
  userBillingData,
  userBillingUnpaid,
} from "../../../Helpers/Functions.js";

const headingArr = [
  CONSTANTS?.SERIAL_NO,
  CONSTANTS?.BILLING_DATE,
  CONSTANTS?.ORDER_TABLE,
  CONSTANTS?.PAYMENT_METHOD,
  CONSTANTS?.AMOUNT,
  CONSTANTS?.STATUS,
  CONSTANTS?.LINK_TO_PAY,
  CONSTANTS?.ACTION,
];

const UserBilling = () => {
  let limit = 6;
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [ShowDropdown, setShowDropdown] = useState(false);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [billing, setBilling] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [latestInvoice, setLatestInvoice] = useState(null);
  const [allPaid, setAllPaid] = useState(true);

  const handleSearch = (newSearch) => {
    setSearch(newSearch);
  };
  const handleChangePage = (newPage) => {
    setCurrentPage(newPage);
  };
  useEffect(() => {
    const formattedFilter = formatFilter(filter);

    userBillingData({
      limit,
      currentPage,
      search,
      filter: formattedFilter,
      setBilling,
      setLoading,
      setCount,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, filter, currentPage]);

  useEffect(() => {
    userBillingUnpaid({
      setLoading,
    })
      .then((res) => {
        setLatestInvoice(res?.data[0]);
        if (res?.data.length > 0) {
          setAllPaid(false);
        }
      })
      .catch((err) => {
        console.log("err: ", err);
      });
  }, []);

  useEffect(() => {
    if (billing && billing.length > 0) {
      setSortedData([...billing]);
    }
  }, [billing]);

  return (
    <Container className={STYLES?.USER_BILLING_PAGE}>
      <UserBillingCards
        latestData={latestInvoice}
        allPaid={allPaid}
        allData={sortedData[0]}
      />

      <Container className={STYLES?.MainContent}>
        <BoldBlackHeading text={CONSTANTS?.INVOICES} />
        <Container className={STYLES?.SEARCH_CONTENT}>
          <ClientSerach
            onSearch={handleSearch}
            setSearch={setSearch}
            filter={filter}
            setFilter={setFilter}
            ShowDropdown={ShowDropdown}
            setShowDropdown={setShowDropdown}
            filterOptions={{
              Status: PaymentStatus,
              "Payment Method": PaymentMethods,
            }}
          />
        </Container>
        <RowSpace margin={20} />
        {!loading ? (
          <>
            {billing?.length > 0 ? (
              <div className={STYLES?.CUSTOM_TABLE}>
                <DataTable
                  customStyles={customStyles}
                  noHeader={true}
                  fixedHeader={true}
                  fixedHeaderScrollHeight={"45dvh"}
                  columns={UserBillingColumn()}
                  data={sortedData}
                  pagination
                  paginationPerPage={limit}
                  paginationRowsPerPageOptions={[10, 20, 30]}
                  paginationComponent={(props) => (
                    <CustomPagination
                      {...props}
                      currentPage={currentPage}
                      limit={limit}
                      count={count}
                      setCurrentPage={setCurrentPage}
                      onChangePage={handleChangePage}
                    />
                  )}
                />
              </div>
            ) : (
              <>
                <DealsTableTitleRow list={headingArr} />

                <CreateNewUser
                  text={CONSTANTS?.NO_INVOICES_YET}
                  text1={"No Invoices"}
                />
              </>
            )}
          </>
        ) : (
          <CustomLoader />
        )}
      </Container>
    </Container>
  );
};

export default UserBilling;
